const request = async (config, isForm = false) => {
  const content = {
    method: config.method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const jwt = localStorage.getItem("jwt") || null;

  if (jwt) {
    content.headers["Authorization"] = "Bearer " + jwt;
  }

  if (isForm) delete content.headers;

  if (config.method !== "GET") content.body = config.params;

  console.log("content", content);

  const response = await fetch(config.url, content);

  // In case of error
  if (!response.ok) {
    const errorMessage = await response.json();
    throw errorMessage;

    // In case of success
  } else {
    try {
      return await response.text();
    } catch (e) {
      return true;
    }
  }
};

export default request;
