import React, { useState, useEffect } from 'react'

// Components
import Stack from '@mui/material/Stack'
import { Box, Button, styled, TextField, Typography } from '@mui/material'

// Helpers
import indexedDB from '../helpers/indexedDB'
import useTime, { TimeTypeEnum } from '../hooks/useTime'
import StyledTime from '../components/inputs/StyledTime'
import MenuGlobal from '../components/Menu'

const StyledTextField = styled(TextField)({
  height: '200px',
  border: '1px solid #ffff',
  color: 'white',
  '& label': {
    color: 'white',
    backgroundColor: '#0a3051',
  },
  '& label.Mui-focused': {
    color: 'white',
    backgroundColor: '#0a3051',
  },
  textArea: {
    color: 'white',
  },
})

const StyledBtnOption = styled(Button)({
  width: '146px',
  height: '65px',
  border: '1px solid #ffff',
  borderRadius: '3px',
  color: 'white',
  fontSize: '24px',
  textTransform: 'none',
  '&:hover': {
    width: '146px',
    height: '65px',
    border: '1px solid #ffff',
    borderRadius: '3px',
    color: 'white',
    fontSize: '24px',
    textTransform: 'none',
  },
})

const StyledBtnOptionSelected = styled(Button)({
  width: '146px',
  height: '65px',
  border: '1px solid #ffff',
  borderRadius: '3px',
  color: '#0A3051',
  fontSize: '24px',
  textTransform: 'none',
  background: '#ffff',
  '&:hover': {
    width: '146px',
    height: '65px',
    border: '1px solid #ffff',
    borderRadius: '3px',
    color: '#0A3051',
    fontSize: '24px',
    textTransform: 'none',
    background: '#ffff',
  },
})

const StyledBtn = styled(Button)({
  width: '100%',
  height: '67px',
  marginTop: '0px',
  padding: '10px',
  borderRadius: '100px',
  background: '#F8B32D',
  fontSize: '14px',
  letterSpacing: '0.1px',
  fontWeight: 500,
  lineHeight: '20px',
  color: '#AA6C00',
  '&:hover': {
    backgroundColor: '#F8B32D',
  },
  '&:disabled': {
    background: 'none',
    border: '1px solid #F8B32D',
    color: '#F8B32D',
  },
})

const ServicePerformed = () => {
  const [service, setService] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const { minute, hour, onTimeChange, setHour, setMinute } = useTime()
  const [isYes, setIsYes] = useState({
    id: 'Yes',
    active: false,
  })

  const [isNo, setIsNo] = useState({
    id: 'No',
    active: false,
  })

  useEffect(() => {
    localStorage.setItem('current_pathname', '/service-performed')
    console.log(localStorage.getItem('current_pathname'))
    ;(async () => {
      await indexedDB.connect()
      const rdoItem = await indexedDB.get(1, 'rdo_item')

      if (rdoItem.service_duration) {
        const timeParts = rdoItem.service_duration.split(':')

        setHour(timeParts[0])
        setMinute(timeParts[1])
      }
      if (rdoItem.service_description && rdoItem.service_duration) {
        setService(rdoItem.service_description)
        if (rdoItem.service_resolved === true) {
          setIsYes({
            id: 'Yes',
            active: true,
          })
          setIsNo({
            id: 'No',
            active: false,
          })
        }
        if (rdoItem.service_resolved === false) {
          setIsNo({
            id: 'No',
            active: true,
          })
          setIsYes({
            id: 'Yes',
            active: false,
          })
        }
        setIsDisabled(false)
      }
    })()
  }, [])

  const verifyFields = async () => {
    if (service.length && (isNo.active || isYes.active)) {
      setIsDisabled(false)
    }
  }

  const handleServiceInput = (e) => {
    setService(e.target.value)
    verifyFields()
  }

  const isClicked = (event) => {
    const id = event.target.id
    if (service) {
      console.log(service)
      setIsDisabled(false)
    }
    if (id === 'Yes') {
      setIsYes({
        id: id,
        active: true,
      })
      setIsNo({
        id: 'No',
        active: false,
      })
    }
    if (id === 'No') {
      setIsNo({
        id: id,
        active: true,
      })
      setIsYes({
        id: 'Yes',
        active: false,
      })
    }
  }

  const handleServiceDescription = async () => {
    setIsDisabled(true)
    await indexedDB.connect()

    const rdoItem = await indexedDB.get(1, 'rdo_item')
    rdoItem.service_description = service
    rdoItem.service_duration = `${hour}:${minute}`

    if (isYes.active) {
      rdoItem.service_resolved = true
    }

    if (isNo.active) {
      rdoItem.service_resolved = false
    }

    await indexedDB.update(1, rdoItem, 'rdo_item')

    localStorage.removeItem('current_pathname')
    window.location.href = '/confirmed-service-performed'
  }

  return (
    <div className="container">
      <Box sx={{ width: '100%' }}>
        <MenuGlobal
          hasReturnButton={{ show: true, toPathname: '/end-photos' }}
        />
      </Box>
      <div>
        <h1>O que foi feito?</h1>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {
              m: 1,
              width: '311px',
              height: '200',
              borderRadius: '4px',
              margin: 'none',
            },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <StyledTextField
              id="outlined-multiline-static"
              label="Descreva abaixo o serviço realizado"
              multiline
              rows={7}
              value={service}
              onChange={handleServiceInput}
            />
          </div>
        </Box>
        <p className="p-roboto-bold-16 lightBlue mt-15">
          Quantas horas durou o serviço
        </p>
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledTime
            time={hour}
            handleChangeTime={(e) => {
              onTimeChange(e, TimeTypeEnum.HOUR)
              verifyFields()
            }}
            timeType={TimeTypeEnum.HOUR}
          />
          <Typography variant="h3" sx={{ margin: '0px 15px 40px' }}>
            :
          </Typography>
          <StyledTime
            className=""
            time={minute}
            handleChangeTime={(e) => {
              onTimeChange(e, TimeTypeEnum.MINUTE)
              verifyFields()
            }}
            timeType={TimeTypeEnum.MINUTE}
          />
        </Box>
        <p className="p-roboto-bold-16 lightBlue mt-15">Resolveu?</p>

        <Stack
          sx={{
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            marginBottom: '150px',
          }}
          spacing={2}
          direction="row"
        >
          {isNo.active ? (
            <StyledBtnOptionSelected
              variant="outlined"
              onClick={isClicked}
              id={isNo.id}
            >
              Não
            </StyledBtnOptionSelected>
          ) : (
            <StyledBtnOption
              variant="outlined"
              onClick={isClicked}
              id={isNo.id}
            >
              Não
            </StyledBtnOption>
          )}
          {isYes.active ? (
            <StyledBtnOptionSelected
              variant="outlined"
              onClick={isClicked}
              id={isYes.id}
            >
              Sim
            </StyledBtnOptionSelected>
          ) : (
            <StyledBtnOption
              variant="outlined"
              onClick={isClicked}
              id={isYes.id}
            >
              Sim
            </StyledBtnOption>
          )}
        </Stack>

        <StyledBtn
          onClick={handleServiceDescription}
          {...(isDisabled ? { disabled: true } : { disabled: false })}
        >
          PRÓXIMO PASSO
        </StyledBtn>
      </div>
    </div>
  )
}

export default ServicePerformed
