import { Box } from "@mui/material";
import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// styles
import styles from "../styles/components/photoList.module.scss";

function PhotosList({ value, deleteFile }) {
  const deleteFileHandler = () => {
    deleteFile(value);
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p className={styles["p"]}> {value.name}</p>
      <RemoveCircleIcon
        sx={{ color: "#EF1B4E", fontSize: "25px", cursor: "pointer" }}
        onClick={deleteFileHandler}
      />
    </Box>
  );
}

export default PhotosList;
