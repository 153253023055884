import React from "react";

//Components
import CloseIcon from "@mui/icons-material/Close";

//Styles
import styles from "../styles/components/modal.module.scss";

const Modal = ({ encoded, closeModal }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <img src={encoded} alt="" />
      </div>
      <div className={styles["close-button-container"]}>
        <CloseIcon
          onClick={closeModal}
          sx={{
            color: "#ffffff",
            fontSize: "40px",
            "&:hover": {
              opacity: "0.6",
            },
          }}
        />
      </div>
    </div>
  );
};

export default Modal;
