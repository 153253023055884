import React, { useState, useEffect } from "react";

// Components
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Styles
import styles from "../styles/pages/startShiftHour.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";
import useTime, { TimeTypeEnum } from "../hooks/useTime";
import StyledTime from "../components/inputs/StyledTime";
import ShiftObservation from "../components/ShiftObservation";
import useShiftObservation from "../hooks/useShiftObervartion";
import MenuGlobal from "../components/Menu";

const StyledBtn = styled(Button)({
  width: "100%",
  height: "67px",
  padding: "10px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

const StartShiftHour = () => {
  const [periodType, setPeriodType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const { hour, minute, setHour, setMinute, onTimeChange, handleTimeDisplay } =
    useTime();
  const {
    observation,
    hasShiftObservation,
    setObservation,
    handleShiftObservationChange,
    handleHasShiftObservationChange,
  } = useShiftObservation();

  useEffect(() => {
    localStorage.setItem("current_pathname", "/start-shift");
    console.log(localStorage.getItem("current_pathname"));
    (async () => {
      await indexedDB.connect();
      const rdoItem = await indexedDB.get(1, "rdo_item");
      setPeriodType(rdoItem.period_type);

      if (rdoItem.period_start_datetime) {
        const time = rdoItem.period_start_datetime.split(" ")[1].split(":");

        setHour(time[0]);
        setMinute(time[1]);
      } else {
        handleTimeDisplay();
      }
      if (rdoItem.period_start_datetime_observation) {
        setObservation(rdoItem.period_start_datetime_observation);
      }
    })();
  }, []);

  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const handleStartHour = async () => {
    setIsDisabled(true);
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");
    rdoItem.period_start_datetime = `${year}-${month}-${day} ${hour}:${minute}:00`;
    rdoItem.period_start_datetime_observation = observation;
    console.log("StartShiftHour rdoItem", rdoItem);
    await indexedDB.update(1, rdoItem, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/register-location";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <MenuGlobal
          hasReturnButton={{ show: true, toPathname: "/shift-select" }}
        />
        <h1 className={styles["h1"]}>Cadastre o horário do início</h1>
      </Box>

      <hr className={styles["hr"]} />
      <p className="p-roboto-bold-16 lightBlue">
        Turno da {periodType === "morning" ? "Manhã" : "Tarde"}
      </p>

      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "72px",
          // marginBottom: "165px"
        }}
      >
        <StyledTime
          time={hour}
          handleChangeTime={(e) => onTimeChange(e, TimeTypeEnum.HOUR)}
          timeType={TimeTypeEnum.HOUR}
        />
        <Typography variant="h3" sx={{ margin: "0px 15px 40px" }}>
          :
        </Typography>
        <StyledTime
          className=""
          time={minute}
          handleChangeTime={(e) => onTimeChange(e, TimeTypeEnum.MINUTE)}
          timeType={TimeTypeEnum.MINUTE}
        />
      </Box>
      <Box
        sx={{
          margin: "10px 0px 40px 0",
        }}
      >
        <ShiftObservation
          observation={observation}
          hasShiftObservation={hasShiftObservation}
          handleShiftObservationChange={handleShiftObservationChange}
          handleHasShiftObservationChange={handleHasShiftObservationChange}
        />
      </Box>
      <StyledBtn
        onClick={handleStartHour}
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        PRÓXIMO PASSO
      </StyledBtn>
    </div>
  );
};

export default StartShiftHour;
