import axios from "axios";
import toast from "react-hot-toast";
// https://api.voda.app.br/api/rdo-itens.php
export const baseURL = "https://api.voda.app.br/api/";
export const loginURL = `${baseURL}login.php`;
export const rdoItemsURL = `${baseURL}rdo-itens.php`;
export const rdoEffortsURL = `${baseURL}rdo-efforts.php`;
export const sendApprovalURL = `${baseURL}send_approval.php`;
export const getChecklistURL = `${baseURL}checklist.php`;
export const getDriversURL = `${baseURL}get-drivers.php`;
export const senChecklistURL = `${baseURL}answers.php`;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const api = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,

  login: async (data) => {
    const response = await api.post(loginURL, data);
    return response.data;
  },

  sendRdoItems: async (data) => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const response = await api.post(rdoItemsURL, data, config).catch((err) => {
      console.log("ERRO AXIOS", err.response.status);
      return response.data;
    });
    return response.data;
  },

  getRdoEfforts: async () => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const response = await api.get(rdoEffortsURL, config).catch((err) => {
      console.log("ERRO AXIOS", err.response.status);

      if (err.response.status === 401) {
        localStorage.removeItem("jwt");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    });

    return response.data;
  },

  sendApproval: async (data) => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const response = await api
      .post(sendApprovalURL, data, config)
      .catch((err) => {
        console.log("ERRO AXIOS", err.response.status);
        return response.data;
      });
    return response.data;
  },

  getApproval: async () => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const userId = localStorage.getItem("user_id");
    const response = await api
      .get(`${sendApprovalURL}?id=${userId}`, config)
      .catch((err) => {
        console.log("ERRO AXIOS", err.response.status);
        return response.data;
      });
    return response.data;
  },

  getChecklistQuestions: async () => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const response = await api.get(getChecklistURL, config).catch((err) => {
      console.error(err);
      return response.data;
    });

    console.log(response);

    return response.data;
  },

  getDrivers: async () => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const response = await api.get(getDriversURL, config).catch((err) => {
      console.error(err);
      return response.data;
    });

    console.log("RESPOSTA GET DRIVERS", response);

    return response.data;
  },

  sendChecklistQuestions: async (data) => {
    const jwt = localStorage.getItem("jwt") || null;

    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    const fetch = api.post(senChecklistURL, data, config).catch((err) => {
      console.error(err);
      return response.data;
    });
    toast.promise(fetch, {
      loading: "Enviando",
      success: "Enviado com sucesso!",
      error: "Algo deu errado, tente novamente!",
    });
    const response = await fetch;

    console.log(response);
    return response.data;
  },
};

export default api;
