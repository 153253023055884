import toast, { Toaster } from "react-hot-toast";
import { MenuContext } from "../../contexts/MenuContext";
import indexedDB from "../../helpers/indexedDB";

export default function MenuProvider({ children }) {
  const handleLogoutRequest = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleReturn = (toPathname) => {
    localStorage.removeItem("current_pathname");
    window.location.href = toPathname;
  };

  const clearStorage = async () => {
    try {
      await indexedDB.connect();
      const rdoItems = await indexedDB.getAll("rdo_items");
      const rdoItem = await indexedDB.getAll("rdo_item");

      console.log(rdoItem.length, rdoItems.length);

      if (rdoItems.length) {
        for (const rdoItem of rdoItems) {
          await indexedDB.delete(rdoItem.key, "rdo_items");
        }
      }

      if (rdoItem.length) {
        for (const item of rdoItems) {
          await indexedDB.delete(item.key, "rdo_item");
        }
      }

      if (rdoItems.length === 0 && rdoItem.length === 0) {
        localStorage.clear();
        window.location.reload();
        return;
      }

      localStorage.clear();
      toast.success("Todos os registros foram deletados");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Erro ao deletar os registros,", error.message);
      console.log(error);
    }
  };

  return (
    <MenuContext.Provider
      value={{ clearStorage, handleLogoutRequest, handleReturn }}
    >
      <Toaster />
      {children}
    </MenuContext.Provider>
  );
}
