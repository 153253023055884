import * as React from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const UserAlert = ({ alertType, message, closeAlert }) => {
  return (
    <Alert
      severity={alertType}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={closeAlert}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ position: "fixed", top: 10, zIndex: 99999 }}
    >
      {message}
    </Alert>
  );
};

export default UserAlert;
