import React, { useEffect, useState } from "react";

// Components
import Button from "@mui/material/Button";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Box, styled } from "@mui/material";

// Helpers
import indexedDB from "../helpers/indexedDB";

// const StyledStartButton = styled(Button)({
//   width: "100%",
//   height: "68px",
//   padding: "10px 24px",
//   marginTop: "100px",
//   marginBottom: "24px",
//   border: "1px solid ##5DB8FF",
//   borderRadius: "100px",
//   fontSize: "14px",
//   letterSpacing: "0.1px",
//   fontWeight: 500,
//   lineHeight: "20px",
//   color: "#0A3051",
//   backgroundColor: "#5DB8FF",
//   "&:hover": {
//     backgroundColor: "#5DB8FF",
//   },
// });

const StyledEndWorkDayButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  marginBottom: "110px",
  border: "1px solid #FFFFFF",
  borderRadius: "115px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#FFFFFF",
});

const StyledAnotherTurnButton = styled(Button)({
  width: "100%",
  height: "67px",
  padding: "10px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

const FinishedShift = () => {
  const [periodType, setPeriodType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    localStorage.setItem("current_pathname", "/finished-shift");
    console.log(localStorage.getItem("current_pathname"));

    (async () => {
      await indexedDB.connect();
      const rdoItem = await indexedDB.get(1, "rdo_item");

      setPeriodType(rdoItem.period_type);
    })();
  }, []);

  const handleReturn = () => {
    localStorage.removeItem("current_pathname");
    const needHours = localStorage.getItem("NeedHoursEndShift");
    if (needHours === "true") {
      window.location.href = "/end-shift";
    } else {
      window.location.href = "/confirmed-service-performed";
    }
  };

  const handleHomeReturn = async () => {
    setIsDisabled(true);
    localStorage.removeItem("current_pathname");
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");
    await indexedDB.insert(rdoItem, "rdo_items");
    await indexedDB.update(1, {}, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/home";
  };

  const handleEndWorkDay = async () => {
    setIsDisabled(true);
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");
    await indexedDB.insert(rdoItem, "rdo_items");
    await indexedDB.update(1, {}, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/ending-work-day";
  };

  const handleStartShift = async () => {
    setIsDisabled(true);
    localStorage.removeItem("current_pathname");
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");
    await indexedDB.insert(rdoItem, "rdo_items");
    await indexedDB.update(
      1,
      {
        effort_id: rdoItem.effort_id,
        effort_location: rdoItem.effort_location,
        effort_nad: rdoItem.effort_nad,
        effort_title: rdoItem.effort_title,
        service_type: rdoItem.service_type,
      },
      "rdo_item"
    );

    localStorage.removeItem("current_pathname");
    window.location.href = "/shift-select";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <ArrowCircleLeftOutlinedIcon
          sx={{
            color: "white",
            fontSize: "45px",
            marginBottom: "15px",
          }}
          onClick={handleReturn}
        />
      </Box>

      <h1>
        Parabéns, o turno da {periodType === "morning" ? "manhã" : "tarde"} foi
        finalizado
      </h1>

      {/* <StyledStartButton
        variant="outlined"
        onClick={handleHomeReturn}
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        Voltar para o início
      </StyledStartButton> */}

      <StyledEndWorkDayButton
        variant="outlined"
        onClick={handleEndWorkDay}
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        Finalizar dia de trabalho
      </StyledEndWorkDayButton>

      <StyledAnotherTurnButton
        variant="outlined"
        onClick={handleStartShift}
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        Iniciar outro turno
      </StyledAnotherTurnButton>
    </div>
  );
};

export default FinishedShift;
