import toast from "react-hot-toast";
import request from "./request";
import indexedDB from "./indexedDB";
// import api from "./api";

export const sendStoredItems = async () => {
  try {
    await indexedDB.connect();
    const rdoItems = await indexedDB.getAll("rdo_items");
    const signature = await indexedDB.get(1, "signature");

    if (window.navigator.onLine && rdoItems.length) {
      toast(`Tentando enviar registros...`);
      console.log(`Tentando enviar registros...`);

      const sanitizedRdoItems = rdoItems.reduce((acc, cur) => {
        const startPhotos = cur.service_start_photos.map(
          (service_photo) => service_photo.encoded
        );
        const endPhotos = cur.service_end_photos.map(
          (service_photo) => service_photo.encoded
        );

        const rdoItem = {
          ...cur,
          service_start_photos: startPhotos,
          service_end_photos: endPhotos,
          service_duration: cur.service_duration.replace(":", "."),
        };

        delete rdoItem.key;

        acc.push(rdoItem);

        return acc;
      }, []);

      const userId = localStorage.getItem("user_id");
      const data = {
        user_id: userId,
        signature: signature,
        rdo_items: sanitizedRdoItems,
      };

      const response = await request({
        method: "POST",
        url: "https://api.voda.app.br/api/rdo-itens.php",
        params: JSON.stringify(data),
      });

      if (response.status && response.status === "error") {
        console.log(`REQUEST NAO ENVIADA`);
        toast.error(
          `tivemos um problema ao enviar os registros, tente novamente mais tarde.`
        );
        await indexedDB.connect();
        await indexedDB.update(1, signature, "signature");
      } else {
        await indexedDB.connect();
        for (const rdoItem of rdoItems) {
          await indexedDB.delete(rdoItem.key, "rdo_items");
        }
        localStorage.removeItem("rdo_sended");
        console.log(`REQUEST ENVIADA`);
        toast.success(`Os registros foram enviados com sucesso.`);
      }
    } else {
      console.log(`There is no stored records...`);
      toast(`Não há registros para serem enviados.`);
    }
  } catch (err) {
    toast.error(`${err.message}`);
    console.log(err);
  }
};
