import React, { useRef, useState, useEffect } from "react";

import toast, { Toaster } from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { Box, CircularProgress, styled } from "@mui/material";

// Components
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import MenuGlobal from "../components/Menu";

// Styles
import styles from "../styles/pages/summaryDay.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";
// import request from "../helpers/request";

//api
import api from "../services/api";

const StyledSendButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  border: "1px solid #F8B32D",
  borderRadius: "100px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  backgroundColor: "#F8B32D",
  marginBottom: "55px",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
});

const SummaryDay = () => {
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState("");
  const [rdoItems, setRdoItems] = useState([]);
  const [rdoItemsMorning, setRdoItemsMorning] = useState([]);
  const [rdoItemsAfternoon, setRdoItemsAfternoon] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    localStorage.setItem("current_pathname", "/summary-day-1");
    console.log(localStorage.getItem("current_pathname"));

    (async () => {
      await indexedDB.connect();
      const rdoItems = await indexedDB.getAll("rdo_items");

      setRdoItems(rdoItems);

      setRdoItemsMorning(
        rdoItems.filter((item) => item.period_type === "morning")
      );

      setRdoItemsAfternoon(
        rdoItems.filter((item) => item.period_type === "afternoon")
      );

      if (rdoItems.length > 0) {
        setIsLoading(false);
      }
    })();
  }, []);

  const date = new Date().toLocaleDateString("pt-BR");

  const closeModal = () => {
    setModal("");
  };

  const closeAlert = () => {
    setAlert(null);
  };

  let sigPad = useRef({});
  let data = "";

  function clear() {
    sigPad.current.clear();
    setShow(false);
  }

  function getBase64Signature() {
    data = sigPad.current.toDataURL();
    console.log(data);
    return data;
  }

  function signatureChange() {
    sigPad.current.isEmpty(false);
    setShow(true);
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    // Limpa os arrays de itens da manhã e da tarde
    setRdoItemsAfternoon([]);
    setRdoItemsMorning([]);

    //Lógica para tratar a assinatura
    const signature = getBase64Signature();
    console.log("SIGNATURE: ", signature);

    // Verifica se o usuário está online
    if (window.navigator.onLine) {
      // Formata os itens a serem enviados
      const sanitizedRdoItems = rdoItems.reduce((acc, cur) => {
        const startPhotos = cur.service_start_photos.map(
          (service_photo) => service_photo.encoded
        );
        const endPhotos = cur.service_end_photos.map(
          (service_photo) => service_photo.encoded
        );

        const rdoItem = {
          ...cur,
          service_start_photos: startPhotos,
          service_end_photos: endPhotos,
          service_duration: cur.service_duration.replace(":", "."),
        };

        // Remove a chave 'key' do item formatado
        delete rdoItem.key;

        // Adiciona o item formatado ao array de itens formatados
        acc.push(rdoItem);

        return acc;
      }, []);
      console.log("RDO ITENS: ", sanitizedRdoItems);

      // Cria o objeto de dados a serem enviados
      const data = {
        user_id: localStorage.getItem("user_id"),
        signature: signature,
        rdo_items: sanitizedRdoItems,
      };

      try {
        // Envia os dados para o servidor
        // const response = await request({
        //   method: "POST",
        //   url: "https://api.voda.app.br/api/rdo-itens.php",
        //   mode: "no-cors",
        //   params: JSON.stringify(data),
        // });

        const fetch = api.sendRdoItems(data);
        toast.promise(fetch, {
          loading: "Enviando...",
          success: "Registro feito com sucesso!",
          error: "Erro ao enviar o registro",
        });

        const response = await fetch;
        console.log(response);

        // Verifica a resposta do servidor
        if (response.status && response.status === "error") {
          toast.error(response.message);
          await indexedDB.connect();
          await indexedDB.update(1, signature, "signature");
          localStorage.removeItem("current_pathname");
        } else {
          // Atualiza o IndexedDB removendo os itens enviados
          await indexedDB.connect();
          for (const rdoItem of rdoItems) {
            await indexedDB.delete(rdoItem.key, "rdo_items");
          }
          localStorage.removeItem("current_pathname");
          localStorage.setItem("rdo_sended", true);
          setTimeout(() => {
            localStorage.setItem("current_pathname", "/summary-day-2");
            window.location.href = `/summary-day-2`;
          }, 500);
        }
      } catch (err) {
        console.log(err);
        await indexedDB.connect();
        await indexedDB.update(1, signature, "signature");
        if (err.message === "Usuário não autorizado") {
          toast("Usuário não autorizado, dados salvos localmente");
        } else {
          toast.error("Ocorreu um erro ao enviar o registro: " + err.message);
        }
        localStorage.clear();
        localStorage.setItem("rdo_sended", true);
        setTimeout(() => {
          localStorage.setItem("current_pathname", "/summary-day-2");
          window.location.href = `/summary-day-2`;
        }, 500);
      }
    } else {
      // Se o usuário estiver offline, exibe uma mensagem de sucesso e atualiza o IndexedDB
      toast.success("Registro feito sem Internet, dados salvos localmente");
      await indexedDB.connect();
      await indexedDB.update(1, signature, "signature");
      localStorage.removeItem("current_pathname");
      localStorage.setItem("rdo_sended", true);
      setTimeout(() => {
        window.location.href = `/home`;
      }, 500);
    }

    // Define isLoading como false após o envio ter sido concluído
    setIsLoading(false);
  };

  return (
    <div className="container">
      <Toaster />
      {!isLoading ? (
        <>
          {alert && (
            <Alert
              alertType={alert.alertType}
              closeAlert={closeAlert}
              message={alert.message}
            />
          )}
          <Box sx={{ width: "100%" }}>
            <MenuGlobal hasReturnButton={{ show: false, toPathname: "" }} />
            <h1 className={styles["h1"]}>
              Confira o <br /> resumo do dia
            </h1>
          </Box>

          <div className={styles.resume}>
            <h5>
              Empenho(s) : <br />
              {[
                ...new Map(
                  rdoItems.map((item, key) => [item[key], item])
                ).values(),
              ].map((rdoItem, index) => {
                return (
                  <div key={"nad-" + index}>
                    <br />
                    {rdoItem.effort_nad}
                    <br />
                  </div>
                );
              })}
            </h5>
            <br />
            <h5>
              Cliente(s) : <br />
              {[
                ...new Map(
                  rdoItems.map((item, key) => [item[key], item])
                ).values(),
              ].map((rdoItem, index) => {
                return (
                  <div key={"title-" + index}>
                    <br />
                    {rdoItem.effort_title}
                    <br />
                  </div>
                );
              })}
            </h5>
            <br />
            <h5>
              Obra(s) : <br />
              {[
                ...new Map(
                  rdoItems.map((item, key) => [item[key], item])
                ).values(),
              ].map((rdoItem, index) => {
                return (
                  <div key={"location-" + index}>
                    <br />
                    {rdoItem.effort_location}
                    <br />
                  </div>
                );
              })}
            </h5>
            <br />
          </div>

          <div className={styles.released}>
            <h5>Lançado em {date}</h5>
          </div>

          {rdoItemsMorning.length > 0 && (
            <div>
              <div className={styles.morningPeriod}>
                <div className={styles.period}>
                  <h5>Período da manhã</h5>
                  <p>
                    Início{" "}
                    {rdoItemsMorning[0].period_start_datetime.split(" ")[1]}{" "}
                  </p>
                  <p>
                    Fim{" "}
                    {
                      rdoItemsMorning[
                        rdoItemsMorning.length - 1
                      ].period_end_datetime.split(" ")[1]
                    }{" "}
                  </p>
                </div>

                <div className={styles.morningButton}>
                  {/* <MorningPeriodEditButton
                        variant="outlined"
                        onClick={handleEditMorning}
                      >
                        Editar
                      </MorningPeriodEditButton> */}
                </div>
              </div>

              {modal && <Modal encoded={modal} closeModal={closeModal} />}

              {rdoItemsMorning.map((rdo, index) => {
                return (
                  <div key={index} className={styles.morningDoing}>
                    <h5>Título</h5>
                    <p>{rdo.service_location}</p>
                    <h5>Observação</h5>
                    <p>{rdo.service_description}</p>
                    <h5>Fotos inicio da manhã (clique para abrir):</h5>
                    {rdo.service_start_photos.map((photo, index) => {
                      return (
                        <div
                          key={`photo1-${index}`}
                          className={styles.photoLinks}
                        >
                          <span
                            onClick={() => {
                              setModal(photo.encoded);
                            }}
                          >
                            {photo.filename}
                          </span>
                        </div>
                      );
                    })}
                    <h5>Fotos fim da manhã (clique para abrir):</h5>
                    {rdo.service_end_photos.map((photo, index) => {
                      return (
                        <div
                          key={`photo2-${index}`}
                          className={styles.photoLinks}
                        >
                          <span
                            onClick={() => {
                              setModal(photo.encoded);
                            }}
                          >
                            {photo.filename}
                          </span>
                        </div>
                      );
                    })}
                    <div className={styles.separation}>
                      <hr />
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {rdoItemsAfternoon.length > 0 && (
            <div>
              <div className={styles.morningPeriod}>
                <div className={styles.period}>
                  <h5>Período da tarde</h5>
                  <p>
                    Início{" "}
                    {rdoItemsAfternoon[0].period_start_datetime.split(" ")[1]}{" "}
                  </p>
                  <p>
                    Fim{" "}
                    {
                      rdoItemsAfternoon[
                        rdoItemsAfternoon.length - 1
                      ].period_end_datetime.split(" ")[1]
                    }{" "}
                  </p>
                </div>

                <div className={styles.morningButton}>
                  {/* <AfternoonPeriodEditButton
                        variant="outlined"
                        onClick={handleEditAfternoon}
                      >
                        Editar
                      </AfternoonPeriodEditButton> */}
                </div>
              </div>

              {modal && <Modal encoded={modal} closeModal={closeModal} />}

              {rdoItemsAfternoon.map((rdo, index) => {
                return (
                  <div key={index} className={styles.morningDoing}>
                    <h5>Título</h5>
                    <p>{rdo.service_location}</p>
                    <h5>Observação</h5>
                    <p>{rdo.service_description}</p>
                    <h5>Fotos inicio da tarde (clique para abrir):</h5>
                    {rdo.service_start_photos.map((photo, index) => {
                      return (
                        <div
                          key={`photo1-${index}`}
                          className={styles.photoLinks}
                        >
                          <span
                            onClick={() => {
                              setModal(photo.encoded);
                            }}
                          >
                            {photo.filename}
                          </span>
                        </div>
                      );
                    })}
                    <h5>Fotos fim da tarde (clique para abrir):</h5>
                    {rdo.service_end_photos.map((photo, index) => {
                      return (
                        <div
                          key={`photo2-${index}`}
                          className={styles.photoLinks}
                        >
                          <span
                            onClick={() => {
                              setModal(photo.encoded);
                            }}
                          >
                            {photo.filename}
                          </span>
                        </div>
                      );
                    })}
                    <div className={styles.separation}>
                      <hr />
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className={styles.signatureBox}>
            <div className={styles.signatureTitleBox}>
              <div className={styles.closeIconButton}>
                {show && (
                  <CloseIcon
                    onClick={clear}
                    sx={{
                      color: "#EF1B4E",
                      fontSize: "40px",
                      zIndex: "999",
                    }}
                  />
                )}
              </div>

              <div className={styles.signatureTitle}>
                <p>Colete a assinatura da pessoa que está fiscalizando</p>
              </div>
            </div>

            <div
              onClick={signatureChange}
              onTouchStart={signatureChange}
              className={styles.signature}
            >
              <SignatureCanvas
                penColor="black"
                backgroundColor="#C4C4C4"
                ref={sigPad}
                canvasProps={{
                  width: 228,
                  height: 667,
                  // className: 'sigCanvas'
                }}
              />
            </div>
          </div>

          <div className={styles.sendButton}>
            <StyledSendButton
              variant="outlined"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Enviar
            </StyledSendButton>
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: "#FFFF",
              position: "fixed",
              top: "50%",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default SummaryDay;
