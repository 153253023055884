import React, { useEffect, useState } from "react";

// Components
import Button from "@mui/material/Button";
import { Box, styled } from "@mui/material";

// Styles
import styles from "../styles/pages/confirmationServicePerformed.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";
import MenuGlobal from "../components/Menu";

const StyledStreetButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  border: "1px solid #F8B32D",
  borderRadius: "100px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  backgroundColor: "#F8B32D",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
});

const StyledAfternoonShiftButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  border: "1px solid ##5DB8FF",
  borderRadius: "100px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#0A3051",
  backgroundColor: "#5DB8FF",
  "&:hover": {
    backgroundColor: "#5DB8FF",
  },
});

const StyledWorkDayButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  border: "1px solid #FFFFFF",
  borderRadius: "100px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#FFFFFF",
});

const ConfirmationServicePerformed = () => {
  const [periodType, setPeriodType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    localStorage.setItem("current_pathname", "/confirmed-service-performed");
    console.log(localStorage.getItem("current_pathname"));

    (async () => {
      await indexedDB.connect();
      const rdoItem = await indexedDB.get(1, "rdo_item");

      setPeriodType(rdoItem.period_type);
    })();
  }, []);

  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const handleEndShift = async () => {
    setIsDisabled(true);
    localStorage.setItem("NeedHoursEndDay", false);
    localStorage.setItem("NeedHoursEndShift", true);

    localStorage.removeItem("current_pathname");
    window.location.href = "/end-shift";
  };

  const handleEndWorkDay = async () => {
    setIsDisabled(true);
    // rdoItems.push(rdoItemParsed);
    // rdoItem = JSON.stringify(rdoItemParsed);
    // rdoItems = JSON.stringify(rdoItems);

    // localStorage.setItem("rdo_item", rdoItem);
    // localStorage.setItem("rdo_items", rdoItems);
    localStorage.setItem("NeedHoursEndDay", true);
    localStorage.setItem("NeedHoursEndShift", false);

    localStorage.removeItem("current_pathname");
    window.location.href = "/ending-work-day";
  };

  const handleAddNewStreet = async () => {
    setIsDisabled(true);
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");
    rdoItem.period_end_datetime = `${year}-${month}-${day} ${hours}:${minutes}:00`;

    await indexedDB.insert(rdoItem, "rdo_items");
    await indexedDB.update(
      1,
      {
        ...rdoItem,
        service_location: "",
        service_start_photos: [],
        service_end_photos: [],
        service_description: "",
        service_resolved: null,
      },
      "rdo_item"
    );

    // Add a Logic that inform that the user already add an item and can't return back to the previous page
    localStorage.removeItem("current_pathname");
    window.location.href = "/register-location";
  };

  return (
    <div className={styles.container}>
      <Box sx={{ width: "100%" }}>
        <MenuGlobal
          hasReturnButton={{ show: true, toPathname: "/service-performed" }}
        />
      </Box>

      <h1>Cadastro feito com sucesso.</h1>

      <div className={styles.streetButton}>
        <StyledStreetButton
          variant="outlined"
          onClick={handleAddNewStreet}
          {...(isDisabled ? { disabled: true } : { disabled: false })}
        >
          Adicionar nova rua
        </StyledStreetButton>
      </div>

      <div className={styles.afternoonShift}>
        <StyledAfternoonShiftButton
          variant="outlined"
          onClick={handleEndShift}
          {...(isDisabled ? { disabled: true } : { disabled: false })}
        >
          Finalizar turno da {periodType === "morning" ? "manhã" : "tarde"}
        </StyledAfternoonShiftButton>
      </div>

      <StyledWorkDayButton
        onClick={handleEndWorkDay}
        variant="outlined"
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        Finalizar dia de trabalho
      </StyledWorkDayButton>
    </div>
  );
};

export default ConfirmationServicePerformed;
