import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  styled,
} from "@mui/material";
import ObservationNameEnum from "../enums/ObservationNameEnum";
import ObservationValueEnum from "../enums/ObservationValueEnum";

export const observationOptions = [
  {
    name: ObservationNameEnum.LACK_PREFECTURE_TEAM,
    id: ObservationValueEnum.LACK_PREFECTURE_TEAM,
  },
  {
    name: ObservationNameEnum.HEAVY_RAINS,
    id: ObservationValueEnum.HEAVY_RAINS,
  },
  {
    name: ObservationNameEnum.IN_MAINTENCE,
    id: ObservationValueEnum.IN_MAINTENCE,
  },
  {
    name: ObservationNameEnum.LACK_OF_TRUCK,
    id: ObservationValueEnum.LACK_OF_TRUCK,
  },
  {
    name: ObservationNameEnum.PREFECTURE_SUSPENSION_NO_RESOURCES,
    id: ObservationValueEnum.PREFECTURE_SUSPENSION_NO_RESOURCES,
  },
  {
    name: ObservationNameEnum.MONTLY_CONTRACT,
    id: ObservationValueEnum.MONTLY_CONTRACT,
  },
  {
    name: ObservationNameEnum.IN_MOBILIZATION_TO_CONTRACT,
    id: ObservationValueEnum.IN_MOBILIZATION_TO_CONTRACT,
  },
  {
    name: ObservationNameEnum.NEW_CT_WAITING_EFFORT,
    id: ObservationValueEnum.NEW_CT_WAITING_EFFORT,
  },
  {
    name: ObservationNameEnum.HOLIDAY,
    id: ObservationValueEnum.HOLIDAY,
  },
  {
    name: ObservationNameEnum.WAITING_SERVICE_ORDER,
    id: ObservationValueEnum.WAITING_SERVICE_ORDER,
  },
  {
    name: ObservationNameEnum.WASTE_DISPOSAL,
    id: ObservationValueEnum.WASTE_DISPOSAL,
  },
  {
    name: ObservationNameEnum.INFLUENCE_OF_THE_SEA,
    id: ObservationValueEnum.INFLUENCE_OF_THE_SEA,
  },
];

const StyledObservation = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },

  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "20px 26px 12px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function ShiftObservation({
  observation,
  hasShiftObservation,
  handleShiftObservationChange,
  handleHasShiftObservationChange,
}) {
  return (
    <>
      <FormGroup sx={{ border: "0" }}>
        <FormControlLabel
          sx={{
            color: "white",
            "& .MuiSvgIcon-root": { color: "white" },
            "& .MuiTypography-root": { fontSize: "12px" },
          }}
          control={
            <Checkbox
              checked={hasShiftObservation}
              onChange={handleHasShiftObservationChange}
            />
          }
          label="Adicionar observação sobre inicio do horário"
        />
      </FormGroup>
      <StyledObservation
        name="anomaly_filter"
        label="Observação"
        variant="outlined"
        select
        fullWidth
        value={observation}
        disabled={!hasShiftObservation}
        onChange={handleShiftObservationChange}
        sx={{ color: "white", border: "1px solid white" }}
      >
        {observationOptions.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </StyledObservation>
    </>
  );
}
