import { Button, styled } from "@mui/material";

const StyledLoginBtn = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },

  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

export const LoginButton = ({ handleSubmit }) => {
  return (
    <StyledLoginBtn
      onClick={handleSubmit}
      variant="contained"
      type="submit"
      fullWidth
      sx={{ marginTop: "96px" }}
      id="submitBtn"
    >
      Acessar
    </StyledLoginBtn>
  );
};
