import { Button, styled } from "@mui/material";

const StyledErrorButton = styled(Button)({
  width: "311px",
  height: "68px",
  background: "none",
  color: "#EF1B4E",
  border: "1px solid #EF1B4E",
  borderRadius: "100px",
  marginTop: "26px",
});

export const ReloadDataButton = ({ handleReloadData }) => {
  return (
    <StyledErrorButton onClick={handleReloadData}>
      Recarregar Dados
    </StyledErrorButton>
  );
};
