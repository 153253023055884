import { useEffect, useState } from "react";
import ChecklistYesOrNo from "../components/ChecklistYesOrNo";
import styles from "../styles/pages/checklist.module.scss";
import { Box, Button, Typography } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import api from "../services/api";
import { WhiteTextField } from "../components/inputs/WhiteTextField";
import { SelectDriver } from "../components/SelectDriver";
import { YesNoPercentage } from "../components/YesNoPercentage";

const CheckListWorkEnd = ({ categories, setChecklistAnswered, options }) => {
  const [responses, setResponses] = useState([]);
  const [yesPercentage, setYesPercentage] = useState(0);
  const [noPercentage, setNoPercentage] = useState(0);
  const [department, setDepartment] = useState("");
  const [driver, setDriver] = useState("");
  const [auxiliaries, setAuxiliaries] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const initResponses = categories?.flatMap((category) =>
      category.questions.map((question) => ({
        question_id: question.id,
        // category_name: category.category_name,
        answered: false,
        answer: null,
        justification: "",
      }))
    );
    setResponses(initResponses);
    console.log(initResponses);
  }, [categories]);

  const calculateYesNoPercentagesFromObjects = (answers) => {
    let yesCount = 0;
    let noCount = 0;

    // Count the occurrences of 'yes' (true) and 'no' (false)
    answers?.forEach((answerObj) => {
      if (answerObj.answer === true) yesCount++;
      else if (answerObj.answer === false) noCount++;
    });

    // Calculate the percentages
    const totalAnswers = answers?.length;
    const yesPercent = totalAnswers ? (yesCount / totalAnswers) * 100 : 0;
    const noPercent = totalAnswers ? (noCount / totalAnswers) * 100 : 0;

    // Update state with the new percentages
    setYesPercentage(yesPercent.toFixed(2));
    setNoPercentage(noPercent.toFixed(2));
  };

  const handleDriverChange = (e) => {
    // console.log(e.target.value)
    setDriver(e.target.value);
  };

  const handleAuxiliaryChange = (index, auxData) => {
    let updatedAuxiliaries = [...auxiliaries];
    updatedAuxiliaries[index] = auxData;
    setAuxiliaries(updatedAuxiliaries);
  };

  const addAuxiliary = () => {
    setAuxiliaries([...auxiliaries, { auxiliar_id: "", auxiliar_name: "" }]);
  };

  const removeAuxiliary = (index) => {
    setAuxiliaries(auxiliaries.filter((_, i) => i !== index));
  };

  const handleAnswerUpdate = (questionId, answer, justification) => {
    const updatedResponses = responses?.map((response) => {
      // console.log(questionId, answer, justification);
      if (response.question_id === questionId) {
        return { ...response, answered: true, answer, justification };
      }
      return response;
    });
    setResponses(updatedResponses);

    // Recalculate the percentages after updating the responses
    calculateYesNoPercentagesFromObjects(updatedResponses);
  };

  const allAnswered = responses?.every((response) => response.answered);

  const allAuxiliariesComplete = auxiliaries.every(
    (aux) => aux.auxiliar_id && aux.auxiliar_name
  );

  const handleSubmit = async () => {
    setIsDisabled(true);
    const parsedData = responses.map(({ answered, ...rest }) => ({ ...rest }));
    console.log(parsedData);
    const userId = localStorage.getItem("user_id") || null;

    if (!userId) {
      toast.error("Usuário não encontrado");
    }

    const bodyData = {
      user_id: userId,
      department: department,
      driver_id: driver,
      auxiliar_data: auxiliaries,
      answers: parsedData,
    };

    console.log("JSON a ser enviado Final > ", bodyData);

    try {
      const response = await api.sendChecklistQuestions(bodyData);

      if (response.status === "success") {
        setChecklistAnswered(true);
        localStorage.setItem("current_pathname", "/end");
        window.location.href = "/end";
      }

      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisabled(false);
    }

    //TODO - clean all the data created by user
  };

  return (
    <>
      <Toaster />
      <div className={styles["container"]}>
        <h1>Questionário de Fim</h1>

        <Box pb={4} className="">
          <WhiteTextField
            label="Operação"
            variant="outlined"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            fullWidth
            margin="normal"
          />
          <SelectDriver
            name="driver"
            label="Motorista/Operador"
            value={driver}
            onChange={handleDriverChange}
            options={options}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ my: 4 }}
            onClick={addAuxiliary}
          >
            Adicionar Auxiliar
          </Button>
          {auxiliaries.map((auxiliary, index) => (
            <div key={index}>
              <SelectDriver
                name={`auxiliar-${index}`}
                label={`Auxiliar ${index + 1}`}
                value={auxiliary.auxiliar_id}
                onChange={(e) =>
                  handleAuxiliaryChange(index, {
                    auxiliar_id: e.target.value,
                    auxiliar_name: options.find(
                      (opt) => opt.id === e.target.value
                    )?.name,
                  })
                }
                options={options}
              />
              <Button onClick={() => removeAuxiliary(index)}>
                Remover Auxiliar
              </Button>
            </div>
          ))}
        </Box>

        {categories ? (
          categories.map((category, index) => {
            return (
              <div className="" key={index}>
                <h2>{category.category_name}</h2>
                <div className="">
                  {category.questions.map((question) => {
                    // Determine if the current question has been answered
                    const currentResponse = responses?.find(
                      (r) => r.question_id === question.id
                    );
                    const answered = currentResponse
                      ? currentResponse.answered
                      : false;
                    return (
                      <ChecklistYesOrNo
                        key={question.id}
                        question={question}
                        answered={answered}
                        onAnswerUpdate={(
                          data // Expecting an object { question_id, answer, justification }
                        ) =>
                          handleAnswerUpdate(
                            data.question_id,
                            data.answer,
                            data.justification
                          )
                        }
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <Typography py={4} variant="h5" color="#fff">
            Carregando...
          </Typography>
        )}

        <YesNoPercentage
          yesPercentage={yesPercentage}
          noPercentage={noPercentage}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !allAnswered ||
            !driver?.length ||
            !department.length ||
            isDisabled ||
            !allAuxiliariesComplete
          }
          sx={{
            color: "#fff",
            "&:disabled": {
              color: "#ffff",
            },
          }}
        >
          Finalizar
        </Button>
      </div>
    </>
  );
};

export default CheckListWorkEnd;
