import React from "react";

// Components
import { List, ListItem, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import styles from "../styles/components/plateList.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";

const ServiceItems = ({ value }) => {
  const handleItemSelected = async () => {
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");
    rdoItem.service_type = value.tipo;
    await indexedDB.update(1, rdoItem, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/shift-select";
  };

  return (
    <div className={styles["container"]}>
      <List sx={{ width: "100%" }} component="nav" aria-label="mailbox folders">
        <hr />
        <ListItem
          button
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "24px",
          }}
          onClick={handleItemSelected}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1 className="p-roboto-black-16 white">{value.tipo}</h1>
          </Box>
          <ArrowForwardIosIcon sx={{ color: "white", fontSize: "28px" }} />
        </ListItem>
      </List>
    </div>
  );
};

export default ServiceItems;
