/* eslint-disable import/no-anonymous-default-export */
export default {
    LACK_PREFECTURE_TEAM: 'Falta de equipe da prefeitura',
    HEAVY_RAINS: 'Chuvas fortes/Rios Cheios',
    IN_MAINTENCE: 'Em manutenção',
    LACK_OF_TRUCK: 'Falta de caminhão',
    LACK_OF_COLLABORATOR: 'Falta de colaborador.',
    PREFECTURE_SUSPENSION_NO_RESOURCES: 'Suspenso pela pref. Sem recurso',
    MONTLY_CONTRACT: 'Contrato Mensal',
    IN_MOBILIZATION_TO_CONTRACT: 'Em mobilização para o contrato',
    NEW_CT_WAITING_EFFORT: 'CT NOVO - Aguardando empenho',
    HOLIDAY: "Feriado",
    WAITING_SERVICE_ORDER: 'Aguardando ordem de serviço',
    WASTE_DISPOSAL: 'Descarte de residuos',
    INFLUENCE_OF_THE_SEA: 'Influencia da maré'
}