import React, { useState, useEffect } from "react";

// Components
import PhotosList from "../components/PhotosList";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";

// Styles
import styles from "../styles/pages/effortPhotos.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";
import MenuGlobal from "../components/Menu";

const StyledBtn = styled(Button)({
  width: "311px",
  height: "67px",
  padding: "10px",
  border: "1px solid #F8B32D",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  // position: "fixed",
  // bottom: "56px",
  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

const StyledFileInput = styled("input")({
  display: "none",
});

const EndPhotos = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    localStorage.setItem("current_pathname", "/end-photos");
    console.log(localStorage.getItem("current_pathname"));
    (async () => {
      await indexedDB.connect();
      const rdoItem = await indexedDB.get(1, "rdo_item");
      if (rdoItem.service_end_photos && rdoItem.service_end_photos.length) {
        const storedFiles = rdoItem.service_end_photos.map(
          (service_end_photo) => {
            return dataURLtoFile(
              service_end_photo.encoded,
              service_end_photo.filename
            );
          }
        );
        setSelectedFile(storedFiles);
        setIsSelected(true);
        setIsDisabled(false);
      }
    })();
  }, []);

  const changeHandler = (event) => {
    const files = Array.from(event.target.files);

    setSelectedFile(files);
    setIsSelected(true);
    setIsDisabled(false);
  };

  const removeFileFromList = (list) => {
    const newItems = selectedFile.filter((item) => {
      return item.name !== list.name;
    });
    if (newItems.length === 0) {
      setIsSelected(false);
      setIsDisabled(true);
    }
    setSelectedFile(newItems);
  };

  const handlePhotosSelected = async () => {
    setIsDisabled(true);
    const toBase64 = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve({
            encoded: reader.result,
            filename: file.name,
          });
        };

        reader.onerror = (error) => reject(error);

        reader.readAsDataURL(file);
      });
    };

    const base64Photos = [];

    for (const photo of selectedFile) {
      const base64Photo = await toBase64(photo);
      base64Photos.push(base64Photo);
    }

    console.log("base64Photos", base64Photos);

    await indexedDB.connect();

    const rdoItem = await indexedDB.get(1, "rdo_item");
    rdoItem.service_end_photos = base64Photos;

    await indexedDB.update(1, rdoItem, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/service-performed";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <MenuGlobal
          hasReturnButton={{ show: true, toPathname: "/start-photos" }}
        />
      </Box>
      <h1 className={styles["h1"]}>
        Agora cadastre as fotos do fim do serviço
      </h1>

      <Box width="100%">
        <label htmlFor="contained-button-file">
          <StyledFileInput
            accept=".jpg, .jpeg"
            capture="filesystem"
            id="contained-button-file"
            type="file"
            name="file"
            onChange={changeHandler}
            multiple
          />
          <Box className={styles["photo-box"]}>
            <PhotoCameraIcon sx={{ fontSize: 80, color: "white" }} />
            <p className={styles["input-text"]}>Enviar fotos</p>
          </Box>
        </label>
        <Box
          sx={{
            width: "100%",
            maxHeight: "142px",
            minHeight: "142px",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "55px",
            padding: "0px",
          }}
        >
          {isSelected ? (
            selectedFile.map((list, index) => {
              return (
                <PhotosList
                  key={index}
                  value={list}
                  deleteFile={removeFileFromList}
                />
              );
            })
          ) : (
            <p className="p-roboto-regular-13 white mt-15">
              Selecione um arquivo para visualizar
            </p>
          )}
        </Box>
      </Box>
      <StyledBtn
        id="submitBtn"
        {...(isDisabled ? { disabled: true } : { disabled: false })}
        onClick={handlePhotosSelected}
      >
        PRÓXIMO PASSO
      </StyledBtn>
    </div>
  );
};

export default EndPhotos;
