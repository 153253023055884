import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, Button, ButtonGroup } from "@mui/material";
import { WhiteTextField } from "./inputs/WhiteTextField";

function ChecklistYesOrNo({
  question,
  onAnswerUpdate,
  answered,
  isManager = false,
}) {
  // State to manage the selected answer and justification
  const [answer, setAnswer] = useState(null);
  const [justification, setJustification] = useState("");

  // Handle click events for Yes/No buttons
  const handleClick = (value) => {
    setAnswer(value);
    // Automatically clear justification if "Yes" is selected
    if (value === "yes") {
      setJustification("");
    }
  };

  // Update parent component whenever the answer or justification changes
  useEffect(() => {
    onAnswerUpdate({
      question_id: question.id,
      answer: answer === "yes" ? true : false,
      justification: answer === "no" ? justification : "",
    });
  }, [answer, justification]);

  return (
    <FormControl
      sx={{ py: 4 }}
      fullWidth
      component="fieldset"
      variant="standard"
      id={question.id}
    >
      <FormLabel sx={{ color: "white" }} component="legend">
        {question.question}
      </FormLabel>
      <ButtonGroup
        fullWidth
        variant="contained"
        aria-label="yes no button group"
      >
        <Button
          color={answered && answer === "yes" ? "success" : "primary"}
          onClick={() => handleClick("yes")}
        >
          {isManager ? "CONFORME" : "SIM"}
        </Button>
        <Button
          color={answered && answer === "no" ? "error" : "primary"}
          onClick={() => handleClick("no")}
        >
          {isManager ? "NÃO CONFORME" : "NÃO"}
        </Button>
      </ButtonGroup>
      {answer === "no" && (
        <WhiteTextField
          fullWidth
          label="Justificativa"
          margin="normal"
          variant="outlined"
          value={justification}
          onChange={(e) => setJustification(e.target.value)}
        />
      )}
    </FormControl>
  );
}

export default ChecklistYesOrNo;
