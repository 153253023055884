import React from "react";

// Components
import { List, ListItem, Box } from "@mui/material";

// Styles
import styles from "../styles/components/effortList.module.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Helpers
import indexedDB from "../helpers/indexedDB";

const EffortList = ({ value }) => {
  const handleEffortSelect = async () => {
    const effortItems = JSON.stringify(value.itens);
    const rdoItem = {
      effort_id: value.effort_id,
      effort_nad: value.effort_nad,
      effort_title: value.title,
      effort_location: value.sale_local,
    };
    localStorage.setItem("items", effortItems);
    await indexedDB.connect();
    await indexedDB.update(1, rdoItem, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/items";
  };

  return (
    <div className={styles["container"]}>
      <List sx={{ width: "100%" }} component="nav" aria-label="mailbox folders">
        <hr />
        <ListItem
          button
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "24px",
          }}
          onClick={handleEffortSelect}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p className="p-roboto-regular-13 white" variant="h6">
              {value.effort_nad}
            </p>
            <p className="p-roboto-regular-13 white" variant="h6">
              {value.title}
            </p>
            <p className="p-roboto-bold-10 white" variant="h6">
              {value.sale_local}
            </p>
          </Box>
          <ArrowForwardIosIcon sx={{ color: "white", fontSize: "30px" }} />
        </ListItem>
      </List>
    </div>
  );
};

export default EffortList;
