import React, { useState, useEffect } from "react";

import { Box } from "@mui/system";
import toast, { Toaster } from "react-hot-toast";

// Components
import { ReloadDataButton } from "../components/buttons/ReloadDataButton";
import EffortList from "../components/EffortList";

// Styles
import styles from "../styles/pages/home.module.scss";

// Helpers
import { sendStoredItems } from "../helpers/sendStoredItems";
import { updateEffortList } from "../helpers/efforts";

// Hooks
import useOnline from "../hooks/useOnline";
import MenuGlobal from "../components/Menu";

const Home = () => {
  const userName = localStorage.getItem("username");

  const [list, setList] = useState([]);

  const { isOnline } = useOnline();

  const handleReloadData = async () => {
    localStorage.removeItem("current_pathname");
    window.location.href = "/home";
  };

  useEffect(() => {
    if (!isOnline) {
      toast.error("Você está offline");
    } else {
      toast.success("Você está online");
    }
  }, [isOnline]);

  useEffect(() => {
    localStorage.setItem("current_pathname", "/home");
    console.log(localStorage.getItem("current_pathname"));
    if (isOnline) {
      setList(null);
      (async () => {
        const rdoSended = localStorage.getItem("rdo_sended");
        if (rdoSended) {
          await sendStoredItems();
        }
        const response = await updateEffortList();
        console.log("RESPOSTA: ", response);
        if (response instanceof Array) {
          setList(response);
        } else {
          setList([]);
        }
      })();
    } else {
      setList(JSON.parse(localStorage.getItem("efforts")));
    }
  }, [isOnline]);

  return (
    <div className="container">
      <Toaster position="top-center" />
      <Box sx={{ width: "100%" }}>
        <MenuGlobal />
        <p className="p-roboto-bold-16 lightBlue">Bom dia {userName}</p>
        <h1 className={styles["h1"]}> Lista de RDO's</h1>
      </Box>
      {list ? (
        list?.length ? (
          list?.map((list, index) => {
            return <EffortList key={index} value={list} />;
          })
        ) : (
          <Box>
            <p className="p-roboto-regular-13 red mt-15 error">
              Ops, não há empenhos cadastrados para seu acesso. Tente recarregar
              os dados clicando no botão abaixo, ou faça contato com a empresa.
            </p>
            <ReloadDataButton handleReloadData={handleReloadData} />
          </Box>
        )
      ) : (
        <p className="p-roboto-regular-13 white mt-15">
          Sincronizando informações
          <br />
          <br />
          Por favor, aguarde.
        </p>
      )}
    </div>
  );
};

export default Home;
