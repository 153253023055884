class IndexedDB {
  constructor(db_name, db_version) {
    this.db_name = db_name;
    this.db_version = db_version;
    this.db = null;
  }

  connect = () => {
    return new Promise((resolve, reject) => {
      if (!window.indexedDB) {
        console.log("Your browser doesn't support IndexedDB");
        reject();
      }

      // Opening a connection to the database
      const request = window.indexedDB.open(this.db_name, this.db_version);

      // on upgrade
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        console.log(
          `Upgrade: Upgrading DB ${db.name} to version ${db.version}`
        );

        if (
          !(
            db.objectStoreNames.contains("rdo_item") &&
            db.objectStoreNames.contains("rdo_items") &&
            db.objectStoreNames.contains("signature")
          )
        ) {
          db.createObjectStore("rdo_item", { autoIncrement: true });
          db.createObjectStore("rdo_items", { autoIncrement: true });
          db.createObjectStore("signature", { autoIncrement: true });
        }
      };

      // on success
      request.onsuccess = (event) => {
        const db = event.target.result;
        console.log(`Success: Using DB ${db.name} at version ${db.version}.`);

        this.db = db;
        resolve();
      };

      // on error
      // Handling errors for the connection opening
      request.onerror = (event) => {
        reject(`Error: ${event.target.error}`);
      };
    });
  };

  insert = (rdo_item, os, key = null) => {
    return new Promise((resolve, reject) => {
      const txn = this.db.transaction(os, "readwrite");

      // get the object store
      const store = txn.objectStore(os);

      const query = !key ? store.add(rdo_item) : store.add(rdo_item, key);

      let result = null;
      // on success
      query.onsuccess = (event) => {
        console.log(
          `Success: Insertion executed successfully (Key: ${event.target.result})`
        );

        result = event.target.result;
      };

      // on error
      query.onerror = (event) => {
        reject(`Error: ${event.target.error}`);
      };

      // on complete
      txn.oncomplete = () => {
        resolve(result);
        console.log("Transaction completed");
      };
    });
  };

  get = (key, os) => {
    return new Promise((resolve, reject) => {
      const txn = this.db.transaction(os, "readonly");

      // get the object store
      const store = txn.objectStore(os);

      const query = store.get(key);

      let result = null;
      // on success
      query.onsuccess = (event) => {
        if (!event.target.result) {
          console.log(
            `Getting failed, rdo item with key = ${key} was not found`
          );
        } else {
          console.log(`Success: Get executed successfully`);

          result = event.target.result;
        }
      };

      // on error
      query.onerror = (event) => {
        reject(`Error: ${event.target.error}`);
      };

      // on complete
      txn.oncomplete = () => {
        console.log("Transaction completed");
        resolve(result);
      };
    });
  };

  getAll = (os) => {
    return new Promise((resolve, reject) => {
      const txn = this.db.transaction(os, "readonly");

      // get the object store
      const objectStore = txn.objectStore(os);

      const rdo_items = [];
      // on success
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const rdo_item = cursor.value;
          const key = cursor.key;

          rdo_items.push({...rdo_item, key });

          // continue next record
          cursor.continue();
        }
      };

      // on complete
      txn.oncomplete = () => {
        console.log("Transaction completed");
        resolve(rdo_items);
      };
    });
  };

  update = (key, newValue, os) => {
    return new Promise((resolve, reject) => {
      const txn = this.db.transaction(os, "readwrite");

      // get the object store
      const store = txn.objectStore(os);

      const query = store.put(newValue, key);

      let result = null;
      // on success
      query.onsuccess = (event) => {
        if (!event.target.result) {
          console.log(
            `Getting failed, rdo item with key = ${key} was not found`
          );
        } else {
          console.log(`Success: Update executed successfully`);

          result = event.target.result;
        }
      };

      // on error
      query.onerror = (event) => {
        reject(`Error: ${event.target.error}`);
      };

      // on complete
      txn.oncomplete = () => {
        console.log("Transaction completed");
        resolve(result);
      };
    });
  };

  delete = (key, os) => {
    return new Promise((resolve, reject) => {
      const txn = this.db.transaction(os, "readwrite");

      // get the object store
      const objectStore = txn.objectStore(os);

      const query = objectStore.delete(key);

      // on success
      query.onsuccess = (event) => {
        console.log(
          `Success: Deletion executed successfully (Key: ${JSON.stringify(
            key
          )})`
        );
      };

      // on error
      query.onerror = (event) => {
        reject(`Error: ${event.target.error}`);
      };

      // on complete
      txn.oncomplete = () => {
        console.log("Transaction completed");
        resolve(key);
      };
    });
  };
}

export default new IndexedDB("voda_db", 1);
