import toast from "react-hot-toast";
import api from "../services/api";

export const updateEffortList = async () => {
  try {
    const fetch = api.getRdoEfforts();
    toast.promise(fetch, {
      loading: "Carregando...",
      success: "Dados atualizados",
      error: "Erro ao atualizar dados",
    });

    const response = await fetch;

    return response;
  } catch (err) {
    console.log(`Lista de RDO's não atualizada: ${err.message}`);
  }
};
