import React, { useState, useEffect } from "react";

// Components
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Box, Button, Stack } from "@mui/material";
import styled from "@emotion/styled";

// Styles
import styles from "../styles/pages/startShiftHour.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";
import MenuGlobal from "../components/Menu";

const StyledBtnOption = styled(Button)({
  width: "146px",
  height: "65px",
  border: "1px solid #ffff",
  borderRadius: "3px",
  color: "white",
  fontSize: "24px",
  textTransform: "none",
  "&:hover": {
    width: "146px",
    height: "65px",
    border: "1px solid #ffff",
    borderRadius: "3px",
    color: "white",
    fontSize: "24px",
    textTransform: "none",
  },
});

const StyledBtnOptionSelected = styled(Button)({
  width: "146px",
  height: "65px",
  border: "1px solid #ffff",
  borderRadius: "3px",
  color: "#0A3051",
  fontSize: "24px",
  textTransform: "none",
  background: "#ffff",
  "&:hover": {
    width: "146px",
    height: "65px",
    border: "1px solid #ffff",
    borderRadius: "3px",
    color: "#0A3051",
    fontSize: "24px",
    textTransform: "none",
    background: "#ffff",
  },
});

const StyledBtn = styled(Button)({
  width: "100%",
  height: "67px",
  padding: "10px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },

  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

const ShiftSelect = () => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const [isMorning, setIsMorning] = useState({
    id: "morning",
    active: false,
  });

  const [isAfternoon, setIsAfternoon] = useState({
    id: "afternoon",
    active: false,
  });

  useEffect(() => {
    localStorage.setItem("current_pathname", "/shift-select");
    console.log(localStorage.getItem("current_pathname"));
    (async () => {
      await indexedDB.connect();
      const rdoItem = await indexedDB.get(1, "rdo_item");

      if (rdoItem.period_type === "morning") {
        setIsMorning({
          id: "morning",
          active: true,
        });
        setIsBtnDisabled(false);
      }
      if (rdoItem.period_type === "afternoon") {
        setIsAfternoon({
          id: "afternoon",
          active: true,
        });
        setIsBtnDisabled(false);
      }
    })();
  }, []);

  // PWA Install Application
  let deferredPrompt;
  window.addEventListener("beforeinstallprompt", function (event) {
    console.log("beforeinstallprompt fired");
    event.preventDefault();
    deferredPrompt = event;
    return false;
  });

  window.addEventListener("appinstalled", (event) => {
    console.log("👍", "appinstalled", event);
    // Clear the deferredPrompt so it can be garbage collected
    window.deferredPrompt = null;
  });

  const isClicked = (event) => {
    // Show the Install Banner to User
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(function (choiceResult) {
        // console.log(choiceResult.outcome);

        if (choiceResult.outcome === "dismissed") {
          console.log("User cancelled installation");
        } else {
          console.log("User added to home screen");
        }
      });

      deferredPrompt = null;
    }

    // Button Event
    const id = event.target.id;
    setIsBtnDisabled(false);
    if (id === "morning") {
      setIsMorning({
        id: id,
        active: true,
      });
      setIsAfternoon({
        id: "afternoon",
        active: false,
      });
    }
    if (id === "afternoon") {
      setIsAfternoon({
        id: id,
        active: true,
      });
      setIsMorning({
        id: "morning",
        active: false,
      });
    }
  };

  const handleShiftSelect = async () => {
    setIsBtnDisabled(true);
    await indexedDB.connect();
    const rdoItem = await indexedDB.get(1, "rdo_item");

    if (isMorning.active) {
      rdoItem.period_type = "morning";
    }
    if (isAfternoon.active) {
      rdoItem.period_type = "afternoon";
    }

    await indexedDB.update(1, rdoItem, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/start-shift";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <MenuGlobal hasReturnButton={{ show: true, toPathname: "/items" }} />
        <h1 className={styles["h1"]}>Vamos lançar dados de qual turno?</h1>
      </Box>

      <hr className={styles["hr"]} />
      <p className="p-roboto-bold-16 lightBlue">Selecione as opções abaixo</p>

      <Stack
        sx={{
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "72px",
          marginBottom: "175px",
        }}
        spacing={2}
        direction="row"
      >
        {isMorning.active ? (
          <StyledBtnOptionSelected
            variant="outlined"
            onClick={isClicked}
            id={isMorning.id}
          >
            Manhã
          </StyledBtnOptionSelected>
        ) : (
          <StyledBtnOption
            variant="outlined"
            onClick={isClicked}
            id={isMorning.id}
          >
            Manhã
          </StyledBtnOption>
        )}
        {isAfternoon.active ? (
          <StyledBtnOptionSelected
            variant="outlined"
            onClick={isClicked}
            id={isAfternoon.id}
          >
            Tarde
          </StyledBtnOptionSelected>
        ) : (
          <StyledBtnOption
            variant="outlined"
            onClick={isClicked}
            id={isAfternoon.id}
          >
            Tarde
          </StyledBtnOption>
        )}
      </Stack>
      <StyledBtn
        onClick={handleShiftSelect}
        {...(isBtnDisabled ? { disabled: true } : { disabled: false })}
      >
        PRÓXIMO PASSO
      </StyledBtn>
    </div>
  );
};

export default ShiftSelect;
