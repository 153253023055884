/* eslint-disable import/no-anonymous-default-export */
export default {
    LACK_PREFECTURE_TEAM: 'F.EQUI PREF.',
    HEAVY_RAINS: 'CHUVA FORTE',
    IN_MAINTENCE: 'MANUT.',
    LACK_OF_TRUCK: 'F.CAMINHÃO',
    LACK_OF_COLLABORATOR: 'F DE COLAB.',
    PREFECTURE_SUSPENSION_NO_RESOURCES: 'S.RECURSO',
    MONTLY_CONTRACT: 'CT.MENSAL',
    IN_MOBILIZATION_TO_CONTRACT: 'EM MOBI',
    NEW_CT_WAITING_EFFORT: 'AG.EMPENHO',
    HOLIDAY: "FR",
    WAITING_SERVICE_ORDER: 'A. OS',
    WASTE_DISPOSAL: 'D.R',
    INFLUENCE_OF_THE_SEA: 'I.M'
}