import React, { useState, useEffect } from "react";

// Components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

// Styles
import styles from "../styles/pages/registerLocation.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";
import MenuGlobal from "../components/Menu";

const StyledTextField = styled(TextField)({
  border: "1px solid #ffff",
  marginTop: "35px",
  height: "200px",
  color: "white",
  "& label": {
    color: "white",
    backgroundColor: "#0a3051",
  },
  "& label.Mui-focused": {
    color: "white",
    backgroundColor: "#0a3051",
  },
  textArea: {
    color: "white",
  },
});

const StyledBtn = styled(Button)({
  width: "100%",
  height: "67px",
  marginTop: "48px",
  padding: "10px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

const RegisterLocation = () => {
  const [location, setLocation] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    localStorage.setItem("current_pathname", "/register-location");
    console.log(localStorage.getItem("current_pathname"));
    (async () => {
      await indexedDB.connect();
      const rdoItem = await indexedDB.get(1, "rdo_item");
      console.log(rdoItem);

      if (rdoItem.service_location) {
        setLocation(rdoItem.service_location);
        setIsDisabled(false);
      }
    })();
  }, []);

  const handleLocationInput = (e) => {
    setLocation(e.target.value);
    setIsDisabled(false);
  };

  const handleLocationRegister = async () => {
    setIsDisabled(true);
    await indexedDB.connect();
    let rdoItem = await indexedDB.get(1, "rdo_item");
    rdoItem.service_location = location;

    await indexedDB.update(1, rdoItem, "rdo_item");

    localStorage.removeItem("current_pathname");
    window.location.href = "/start-photos";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <MenuGlobal
          hasReturnButton={{ show: true, toPathname: "/start-shift" }}
        />
      </Box>
      <h1>Cadastre o local do serviço</h1>

      <div className={styles.textField}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: "311px",
              height: "200",
              borderRadius: "4px",
              margin: "none",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <StyledTextField
              id="outlined-multiline-flexible"
              label="Digite abaixo"
              multiline
              rows={7}
              value={location}
              onChange={handleLocationInput}
            />
          </div>
        </Box>
      </div>

      <StyledBtn
        onClick={handleLocationRegister}
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        PRÓXIMO PASSO
      </StyledBtn>
    </div>
  );
};

export default RegisterLocation;
