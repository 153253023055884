import { useState } from "react";

export default function useShiftObservation() {
  const [observation, _setObservation] = useState('');
  const [hasShiftObservation, _setHasShiftObservation] = useState(false);

  const setObservation = (value) => {
    if(setObservation.length > 0 && !hasShiftObservation) _setHasShiftObservation(true);
    else if(setObservation.length === 0) _setHasShiftObservation(false)
    _setObservation(value)
  }

  const handleShiftObservationChange = (e) => {
    const { value } = e.target;

    setObservation(value);
  };

  const handleHasShiftObservationChange = (e) => {
    const { checked } = e.target;

    _setHasShiftObservation(checked);
    if(!checked) {
      _setObservation('')
    }
    console.log(observation)
  };

  return {
    observation,
    setObservation,
    hasShiftObservation,
    handleShiftObservationChange,
    handleHasShiftObservationChange
  };
}
