import { MenuItem } from "@mui/material";
import { StyledList } from "./inputs/StyledList";

export const SelectDriver = ({ name, label, driver, onChange, options }) => {
  if (!options.length) {
    console.log("pegou");
    return null;
  }
  return (
    <StyledList
      name={name}
      label={label}
      variant="outlined"
      select
      fullWidth
      value={driver}
      onChange={onChange}
      sx={{ color: "white", border: "1px solid white", mt: 2 }}
    >
      {options?.map((option, index) => {
        return (
          <MenuItem key={index} value={option.id}>
            {option.name}
          </MenuItem>
        );
      })}
    </StyledList>
  );
};
