import React, { useState, useEffect } from "react";

// Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material";

// Styles
import styles from "../styles/pages/endingWorkDay.module.scss";

// Helpers
import indexedDB from "../helpers/indexedDB";

const StyledConfirmButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  border: "1px solid #F8B32D",
  borderRadius: "100px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  backgroundColor: "#F8B32D",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
});

const StyledWrongButton = styled(Button)({
  width: "100%",
  height: "68px",
  padding: "10px 24px",
  border: "1px solid #FFFFFF",
  borderRadius: "100px",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#FFFFFF",
});

const EndingWorkDay = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    localStorage.setItem("current_pathname", "/ending-work-day");
    console.log(localStorage.getItem("current_pathname"));
  }, []);

  const handleReturn = async () => {
    setIsDisabled(true);
    await indexedDB.connect();

    const rdoItem = [] | (await indexedDB.get(1, "rdo_item"));

    if (Object.keys(rdoItem).length === 0) {
      const rdoItems = await indexedDB.getAll("rdo_items");

      const rdoItemsKeys = rdoItems.map((rdoItem) => rdoItem.key);
      const lastRdoItemKey = Math.max(...rdoItemsKeys);
      console.log(rdoItems);
      const lastRdoItem = rdoItems.filter(
        (rdoItem) => rdoItem.key === lastRdoItemKey
      )[0];

      if (lastRdoItem) {
        delete lastRdoItem.key;

        console.log(`lastRdoItem`, lastRdoItem);
        console.log(`lastRdoItemKey`, lastRdoItemKey);

        await indexedDB.update(1, lastRdoItem, "rdo_item");
        await indexedDB.delete(lastRdoItemKey, "rdo_items");
      }

      localStorage.removeItem("current_pathname");
      window.location.href = "/finished-shift";
    } else {
      localStorage.removeItem("current_pathname");
      window.location.href = "/confirmed-service-performed";
    }
  };

  const handleConfirmedEnd = async () => {
    setIsDisabled(true);
    localStorage.removeItem("current_pathname");
    const needHoursEndDay = localStorage.getItem("NeedHoursEndDay");

    if (needHoursEndDay === "true") {
      window.location.href = "/end-work-day-hour";
    } else {
      window.location.href = "/summary-day-1";
    }
  };
  return (
    <div className={styles.container}>
      <h1>Você está finalizando o dia de trabalho?</h1>

      <div className={styles.confirmButton}>
        <StyledConfirmButton
          onClick={handleConfirmedEnd}
          variant="outlined"
          {...(isDisabled ? { disabled: true } : { disabled: false })}
        >
          Sim
        </StyledConfirmButton>
      </div>

      <p>
        Clicando acima você concorda que está finalizando o dia de trabalho e
        não será possível fazer novos lançamentos para este dia.
      </p>

      <StyledWrongButton
        variant="outlined"
        onClick={handleReturn}
        {...(isDisabled ? { disabled: true } : { disabled: false })}
      >
        Ops, cliquei errado. Quero voltar!
      </StyledWrongButton>
    </div>
  );
};

export default EndingWorkDay;
