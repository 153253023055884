import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import Login from "./pages/Login";
import Home from "./pages/Home";
import Item from "./pages/Item";
import StartShiftHour from "./pages/StartShiftHour";
import ShiftSelect from "./pages/ShiftSelect";
import EndShiftHour from "./pages/EndShiftHour";
import EndWorkDayHour from "./pages/EndWorkDayHour";
import ServicePerformed from "./pages/ServicePerformed";
import RegisterLocation from "./pages/RegisterLocation";
import ConfirmationServicePerformed from "./pages/ConfirmationServicePerformed";
import StartPhotos from "./pages/StartPhotos";
import EndPhotos from "./pages/EndPhotos";
import EndingWorkDay from "./pages/EndingWorkDay";
import SummaryDay from "./pages/SummaryDay";
import FinishedShift from "./pages/FinishedShift";
import EditEffort from "./pages/EditEffort";
import AfterWorkJustification from "./pages/AfterWorkJustification";

import "./App.css";
import MenuProvider from "./components/providers/MenuProvider";
import api from "./services/api";
import CheckListWorkStart from "./pages/CheckListWorkStart";
import CheckListWorkEnd from "./pages/CheckListWorkEnd";
import CheckListSupervisor from "./pages/CheckListSupervisor";
import End from "./pages/End";

const jwt = localStorage.getItem("jwt") || null;

const App = () => {
  const [showJustificationPage, setShowJustificationPage] = useState(false);
  const [checklistStartAnswered, setChecklistStartAnswered] = useState(false);
  const [checklistEndAnswered, setChecklistEndAnswered] = useState(false);
  const [checklistSupervisorAnswered, setChecklistSupervisorAnswered] =
    useState(false);
  const [questions, setQuestions] = useState({});
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const isUserApproved = localStorage.getItem("is_user_approved") || false;
    // Função para verificar se o horário atual está fora do horário de trabalho
    const isAfterWorkHours = (hour, minute) => {
      const targetHour = 18;
      const targetMinute = 15;
      return (
        hour > targetHour || (hour === targetHour && minute >= targetMinute)
      );
    };

    // Função para verificar se o horário atual está antes do horário de trabalho
    const isBeforeWorkHours = (hour, minute) => {
      const startHour = 7;
      const startMinute = 0;
      return hour < startHour || (hour === startHour && minute < startMinute);
    };

    // Função para obter a hora atual
    const getCurrentTime = () => {
      const currentTime = new Date();
      return {
        hour: currentTime.getHours(),
        minute: currentTime.getMinutes(),
      };
    };

    const getApproval = () => {
      const userId = localStorage.getItem("user_id");
      api
        .getApproval(userId)
        .then((response) => {
          console.log(response);
          if (response === 1) {
            localStorage.setItem("is_user_approved", true);
            setShowJustificationPage(false);
          } else {
            setShowJustificationPage(true);
          }
          return response;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    //Verifica e Mostra tela de Checklist
    const getChecklist = async () => {
      api
        .getChecklistQuestions()
        .then((response) => {
          console.log(response);
          setQuestions(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getDrivers = async () => {
      await api
        .getDrivers()
        .then((response) => {
          console.log("RESPOSTA CLIENTE: ", response);
          if (response instanceof Array) {
            setDrivers(response);
          } else {
            setDrivers([]);
            localStorage.clear();
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // Verifica se a justificativa deve ser exibida com base no horário atual
    const checkJustificationPage = () => {
      const { hour, minute } = getCurrentTime();
      if (isAfterWorkHours(hour, minute) || isBeforeWorkHours(hour, minute)) {
        setInterval(() => {
          getApproval();
        }, 5000);
        clearInterval();
      }
    };

    // Verifica se o usuário está autorizado para acessar o aplicativo
    if (isUserApproved === false) {
      checkJustificationPage();
    }

    if (jwt && (!checklistStartAnswered || !checklistEndAnswered)) {
      getChecklist();
      getDrivers();
    }

    return () => {
      isMounted = false;
    };
  }, [checklistEndAnswered, checklistStartAnswered]);

  if (!jwt) {
    return <Login />;
  }

  if (showJustificationPage) {
    return (
      <AfterWorkJustification
        setShowJustificationPage={setShowJustificationPage}
      />
    );
  }

  if (
    jwt &&
    localStorage.getItem("is_manager") === "S" &&
    !checklistSupervisorAnswered &&
    localStorage.getItem("current_pathname") === "/home"
  ) {
    return (
      <CheckListSupervisor
        categories={questions["Coordenador"]}
        setChecklistAnswered={setChecklistSupervisorAnswered}
        options={drivers}
      />
    );
  }

  if (
    jwt &&
    localStorage.getItem("is_manager") !== "S" &&
    !checklistStartAnswered &&
    localStorage.getItem("current_pathname") === "/home"
  ) {
    return (
      <CheckListWorkStart
        categories={questions["Início"]}
        setChecklistAnswered={setChecklistStartAnswered}
        options={drivers}
      />
    );
  }

  if (jwt && localStorage.getItem("current_pathname") === "/summary-day-2") {
    return (
      <CheckListWorkEnd
        categories={questions["Final"]}
        setChecklistAnswered={setChecklistEndAnswered}
        options={drivers}
      />
    );
  }

  if (jwt) {
    const pathname = localStorage.getItem("current_pathname");
    // console.log('APP, should be in pathname', pathname)
    // console.log('APP, current pathname', window.location.pathname)
    if (pathname && pathname !== window.location.pathname) {
      // console.log('redirecting to',pathname)
      window.location.href = pathname;
      return;
    }
  }

  return (
    <>
      <MenuProvider>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/items" component={Item} />
          <Route exact path="/start-shift" component={StartShiftHour} />
          <Route exact path="/end-shift" component={EndShiftHour} />
          <Route exact path="/end-work-day-hour" component={EndWorkDayHour} />
          <Route exact path="/shift-select" component={ShiftSelect} />
          <Route exact path="/start-photos" component={StartPhotos} />
          <Route exact path="/end-photos" component={EndPhotos} />
          <Route exact path="/service-performed" component={ServicePerformed} />
          <Route exact path="/finished-shift" component={FinishedShift} />
          <Route
            exact
            path="/confirmed-service-performed"
            component={ConfirmationServicePerformed}
          />
          <Route exact path="/register-location" component={RegisterLocation} />
          <Route exact path="/ending-work-day" component={EndingWorkDay} />
          <Route exact path="/summary-day-1" component={SummaryDay} />
          <Route exact path="/edit-effort" component={EditEffort} />
          <Route exact path="/end" component={End} />
        </Switch>
      </MenuProvider>
    </>
  );
};

export default App;
