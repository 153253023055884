import { useState } from "react";

export const TimeTypeEnum = {
  HOUR: 2,
  MINUTE: 1,
};

export default function useTime() {
  const [hour, _setHour] = useState("");
  const [minute, _setMinute] = useState("");

  const handleTimeDisplay = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    setHour(hours);
    setMinute(minutes);
  };

  const preValidateTime = (value) => {
    value = String(Number(value));
    const isValidLenghtForTime = RegExp(/^\d{0,2}$/g);

    if (!isValidLenghtForTime.test(value)) return -1;
    return 1;
  };

  const sanitazeTime = (value) => {
    const timeAsNumber = Number(value);

    if (timeAsNumber < 10) return "0" + timeAsNumber;
    else return String(timeAsNumber);
  };

  const onTimeChange = (e, timeType) => {
    let { value } = e.target;

    if (timeType === TimeTypeEnum.HOUR) {
      setHour(value);
    } else if (timeType === TimeTypeEnum.MINUTE) {
      setMinute(value);
    }
  };

  // const onTimeChange = (e, timeType) => {
  //   let { value } = e.target;

  //   if (timeType === TimeTypeEnum.HOUR) {
  //     setHour(value);
  //   }
  //   else if (timeType === TimeTypeEnum.MINUTE) {
  //     setMinute(value);
  //   }
  // };

  const setHour = (hour) => {
    hour = String(Number(hour));
    const timeAsNumber = Number(hour);
    console.log("as Hours");

    if (!preValidateTime(hour)) return -1;
    hour = sanitazeTime(hour);

    const isValidRange = timeAsNumber < 24;

    if (!isValidRange) {
      return -1;
    }

    _setHour(hour);
  };

  const setMinute = (minute) => {
    minute = String(Number(minute));
    const timeAsNumber = Number(minute);
    console.log("as Minutes");

    if (!preValidateTime(minute)) return -1;
    minute = sanitazeTime(minute);

    const isValidRange = timeAsNumber <= 60;

    if (!isValidRange) {
      return -1;
    }
    _setMinute(minute);
  };

  return {
    hour,
    minute,
    onTimeChange,
    handleTimeDisplay,
    setHour,
    setMinute,
  };
}
