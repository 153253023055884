import React, { useEffect, useState } from "react";

// Components
import ServiceItems from "../components/ServiceItems";
import styled from "@emotion/styled";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import MenuGlobal from "../components/Menu";

const StyledErrorButton = styled(Button)({
  width: "311px",
  height: "68px",
  background: "none",
  color: "#EF1B4E",
  border: "1px solid #EF1B4E",
  borderRadius: "100px",
  marginTop: "26px",
});

const Item = () => {
  const items = localStorage.getItem("items");
  const itemsParsed = JSON.parse(items);
  const [plates, setPlates] = useState(itemsParsed);
  const [isPlate, setIsPlate] = useState(false);

  const userName = localStorage.getItem("username");

  useEffect(() => {
    localStorage.setItem("current_pathname", "/items");
    console.log(localStorage.getItem("current_pathname"));
    if (itemsParsed.length > 0) {
      setPlates(itemsParsed);
      setIsPlate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReloadData = async () => {
    localStorage.removeItem("current_pathname");
    window.location.href = "/home";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <MenuGlobal hasReturnButton={{ show: true, toPathname: "/home" }} />
      </Box>
      <p className="p-roboto-bold-16 lightBlue">{userName}</p>
      <h1 className="h1">Selecione o serviço que irá executar</h1>

      {isPlate ? (
        plates.map((list, index) => {
          return <ServiceItems key={index} value={list} />;
        })
      ) : (
        <Box>
          <p className="p-roboto-regular-13 red mt-15 error">
            Ops, não há veículos cadastrados para seu acesso. Tente recarregar
            os dados clicando no botão abaixo, ou faça contato com a empresa.
          </p>
          <StyledErrorButton onClick={handleReloadData}>
            {" "}
            Recarregar Dados
          </StyledErrorButton>
        </Box>
      )}
      <Box sx={{ width: "100%" }}>
        <hr />
      </Box>
    </div>
  );
};

export default Item;
