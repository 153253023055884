import { Stack, Typography, CircularProgress } from "@mui/material";

export const YesNoPercentage = ({ yesPercentage, noPercentage }) => {
  return (
    <>
      <Stack direction="row" alignItems={"center"} mb={4} spacing={2}>
        <Typography color={"white"}>SIM : {yesPercentage}%</Typography>
        <CircularProgress
          variant="determinate"
          value={yesPercentage}
          color="success"
        />
      </Stack>
      <Stack direction="row" alignItems={"center"} mb={4} spacing={2}>
        <Typography color={"white"}>NÃO : {noPercentage}%</Typography>
        <CircularProgress
          variant="determinate"
          value={noPercentage}
          color="error"
        />
      </Stack>
    </>
  );
};
