import { Box, Button, styled } from "@mui/material";
import MenuGlobal from "../components/Menu";

import styles from "../styles/pages/startShiftHour.module.scss";
import { useEffect } from "react";

const StyledBtn = styled(Button)({
  width: "100%",
  height: "67px",
  padding: "10px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

const End = () => {
  useEffect(() => {
    localStorage.setItem("current_pathname", "/end");
    console.log(localStorage.getItem("current_pathname"));
  }, []);

  const handleHomeReturn = () => {
    localStorage.removeItem("current_pathname");
    window.location.href = "/home";
  };

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <MenuGlobal hasReturnButton={{ show: false, toPathname: "/home" }} />
        <h1 className={styles["h1"]}>RDO Finalizado com sucesso!</h1>
      </Box>
      <StyledBtn onClick={handleHomeReturn}>Voltar a tela inicial</StyledBtn>
    </div>
  );
};

export default End;
