import { TextField, Stack } from "@mui/material";

import styled from "@emotion/styled";

// Styles
import styles from "../../styles/pages/endShiftHour.module.scss";
import { TimeTypeEnum } from "../../hooks/useTime";


const StyledNumberInput = styled(TextField)({
  width: "95px",
  height: "75px",
  border: "2px solid #ffff",
  borderRadius: "3px",
  color: "white",
  input: {
    color: "white",
    border: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "48px",
    lineHeight: "56px",
    padding: 0,
    paddingLeft: "17px",
    "&::-webkit-inner-spin-button": {
      " -webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-outer-spin-button": {
      " -webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function StyledTime({ time, handleChangeTime, timeType, name = "" }) {
  const getTimeLabel = () => {
    if (timeType === TimeTypeEnum.HOUR) return 'Hora';
    else if (timeType === TimeTypeEnum.MINUTE) return 'Minuto';
    else return ''
  }

  const timeLabel = getTimeLabel();
  return (
    <Stack>
      <StyledNumberInput
        name={name}
        type="number"
        inputProps={{ inputMode: "numeric", pattern: "[0-23]*" }}
        value={time}
        onChange={handleChangeTime}
      />
      {timeLabel.length > 0 ? (
        <p className={styles["p"]}>{timeLabel}</p>
      ) :
        ''
      }
    </Stack>
  )
}