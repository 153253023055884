import { useState } from "react";

export default function usePassword() {
  const [isMissingPsw, setIsMissingPsw] = useState(false);
  const [psw, setPsw] = useState({
    password: "",
    showPassword: false,
  });

  const handleChangePsw = (prop) => (event) => {
    setPsw({ ...psw, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPsw({
      ...psw,
      showPassword: !psw.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return {
    isMissingPsw,
    setIsMissingPsw,
    psw,
    handleChangePsw,
    handleClickShowPassword,
    handleMouseDownPassword,
  };
}
