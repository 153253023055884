import { useState, useEffect } from "react";

export default function useOnline() {
  const navigator = window.navigator;
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setTimeout(() => {
        setIsOnline(true);
      }, 1000);
    };
    const handleOffline = () => {
      setTimeout(() => {
        setIsOnline(false);
      }, 1000);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return {
    isOnline,
  };
}
