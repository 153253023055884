import { InputAdornment, TextField, styled } from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import styles from "../../styles/pages/login.module.scss";

const StyledCpfInput = styled(TextField)({
  borderRadius: "4px",
  color: "white",
  "& label": {
    color: "white",
    backgroundColor: "#0a3051",
  },
  "& label.Mui-focused": {
    color: "white",
    backgroundColor: "#0a3051",
  },
  "&.MuiFormControl-root > div": {
    border: "1px solid #ffff",
  },
  "&.MuiFormControl-root > div.Mui-error": {
    border: "1px solid #EF1B4E",
  },

  input: {
    color: "white",
  },
});

export const StyledCPF = ({
  isMissingCpf,
  cpf,
  handleCpfChange,
  cpfClickHandler,
}) => {
  return (
    <StyledCpfInput
      id="outlined-basic"
      label="Digite seu CPF"
      className={styles["input"]}
      {...(isMissingCpf ? { error: true } : "")}
      error={isMissingCpf}
      value={cpf}
      onChange={handleCpfChange}
      margin="normal"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={cpfClickHandler}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <HighlightOffIcon sx={{ color: "white" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};
