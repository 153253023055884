import React from "react";

// Styles
import styles from "../styles/pages/editEffort.module.scss";

// Components
import { Button, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";

const StyledViewBtn = styled(Button)({
  width: "111px",
  height: "40px",
  background: "#F8B32D",
  borderRadius: "100px",
  color: "#AA6C00",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  textTransform: "none",
});

const StyledAddBtn = styled(Button)({
  width: "111px",
  height: "40px",
  background: "none",
  border: "1px solid #F8B32D",
  borderRadius: "100px",
  color: "#F8B32D",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  textTransform: "none",
});

const StyledReturnBtn = styled(Button)({
  width: "311px",
  height: "68px",
  padding: "10px 24px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  position: "fixed",
  bottom: "56px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
});

const EditEffort = () => {
  let rdoItems = localStorage.getItem("rdo_items") || [];
  if (rdoItems.length > 0) {
    rdoItems = JSON.parse(rdoItems);
  }
  let rdoItem = localStorage.getItem("rdo_item");
  const rdoItemParsed = JSON.parse(rdoItem);

  const rdoItemsMorning = rdoItems.filter(
    (item) => item.period_type === "morning"
  );
  const rdoItemsAfternoon = rdoItems.filter(
    (item) => item.period_type === "afternoon"
  );

  const handleReturn = () => {
    window.location.href = "/home";
  };

  return (
    <div className="container">
      <h1>Detalhes do empenho</h1>
      <div className={styles["details"]}>
        <p className="p-roboto-regular-13 white">
          Empenho: {rdoItemParsed.effort_nad}
        </p>
        <p className="p-roboto-regular-13 white">
          Cliente: {rdoItemParsed.effort_title}
        </p>
        <p className="p-roboto-regular-13 white">
          Obra: {rdoItemParsed.effort_location}
        </p>
      </div>
      <div>
        <div className={styles["table"]}>
          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th>Data</th>
                <th>Hr início</th>
                <th>Hr fim</th>
                <th>Ações</th>
              </tr>
            </thead>

            {rdoItemsMorning.map((rdo, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td>{rdo.period_start_datetime.split(" ")[0]}</td>
                    <td>{rdo.period_start_datetime.split(" ")[1]}</td>
                    <td>{rdo.period_end_datetime.split(" ")[1]}</td>
                    <td>
                      <Stack spacing={2} direction="row">
                        <StyledViewBtn startIcon={<VisibilityIcon />}>
                          Visualizar
                        </StyledViewBtn>

                        <StyledAddBtn startIcon={<EditIcon />}>
                          Adicionar
                        </StyledAddBtn>
                      </Stack>
                    </td>
                  </tr>
                </tbody>
              );
            })}

            {rdoItemsAfternoon.map((rdo, index) => {
              return (
                <tbody>
                  <tr>
                    <td>
                      {rdoItemsAfternoon[0].period_start_datetime.split(" ")[0]}
                    </td>
                    <td>
                      {rdoItemsAfternoon[0].period_start_datetime.split(" ")[1]}
                    </td>
                    <td>
                      {
                        rdoItemsMorning[
                          rdoItemsMorning.length - 1
                        ].period_end_datetime.split(" ")[1]
                      }
                    </td>
                    <td>- -</td>
                    <td>- -</td>
                    <td>
                      <Stack spacing={2} direction="row">
                        <StyledViewBtn startIcon={<VisibilityIcon />}>
                          Visualizar
                        </StyledViewBtn>

                        <StyledAddBtn startIcon={<EditIcon />}>
                          Adicionar
                        </StyledAddBtn>
                      </Stack>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>

      <StyledReturnBtn onClick={handleReturn}>
        {" "}
        Voltar para o início
      </StyledReturnBtn>
    </div>
  );
};

export default EditEffort;
