import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledLabelPsw = styled(InputLabel)({
  color: "white",
  backgroundColor: "#0a3051",
});

const StyledPswInput = styled(OutlinedInput)({
  borderRadius: "4px",
  color: "white",
  FormControl: {
    color: "white",
  },
  "&.MuiOutlinedInput-root ": {
    border: "1px solid #ffff",

    "&.Mui-error": {
      border: "1px solid #EF1B4E",
    },

    "&.Mui-error > label": {
      color: "#EF1B4E",
    },
  },
});

export const StyledPassword = ({
  isMissingPsw,
  psw,
  handleChangePsw,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <StyledLabelPsw
        {...(isMissingPsw ? { error: true } : "")}
        htmlFor="outlined-adornment-password"
      >
        Digite sua senha
      </StyledLabelPsw>
      <StyledPswInput
        id="outlined-adornment-password"
        type={psw.showPassword ? "text" : "password"}
        value={psw.password}
        onChange={handleChangePsw("password")}
        {...(isMissingPsw ? { error: true } : "")}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              sx={{ color: "white" }}
            >
              {psw.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Digite sua senha"
      />
    </FormControl>
  );
};
