import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import { StyledCPF } from "../components/inputs/StyledCPF";
import { StyledPassword } from "../components/inputs/StyledPassword";
import { LoginButton } from "../components/buttons/LoginButton";

import api from "../services/api";

import useCpf from "../hooks/useCpf";
import usePassword from "../hooks/usePassword";

import styles from "../styles/pages/login.module.scss";

import logo from "../images/logo.png";

const Login = () => {
  const [loginError, setLoginError] = useState(null);
  const {
    cpf,
    isMissingCpf,
    setIsMissingCpf,
    handleCpfChange,
    cpfClickHandler,
  } = useCpf();

  const {
    psw,
    isMissingPsw,
    setIsMissingPsw,
    handleChangePsw,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = usePassword();

  useEffect(() => {
    //TODO - melhorar a Lógica de verificar se os items devem ser enviados ou não ao Localstorage
    const rdoSended = localStorage.getItem("rdo_sended") || null;
    if (!rdoSended) {
      localStorage.removeItem("rdo_sended");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isMissingCpf = !cpf;
    const isMissingPsw = !psw.password;

    if (isMissingCpf && isMissingPsw) {
      setIsMissingCpf(true);
      setIsMissingPsw(true);
    } else if (isMissingCpf) {
      setIsMissingCpf(true);
    } else if (isMissingPsw) {
      setIsMissingPsw(true);
    } else if (isMissingCpf && !isMissingPsw) {
      setIsMissingCpf(true);
      setIsMissingPsw(false);
    } else if (!isMissingCpf && isMissingPsw) {
      setIsMissingCpf(false);
      setIsMissingPsw(true);
    } else {
      setIsMissingCpf(false);
      setIsMissingPsw(false);
    }

    //TODO - adicionar um ref no Botão de Login
    const submitBtn = document.getElementById("submitBtn");

    try {
      if (!isMissingCpf && !isMissingPsw) {
        submitBtn.innerHTML = "Entrando...";
        submitBtn.disabled = true;
        const data = {
          cpf: cpf,
          password: psw.password,
        };

        const fetch = api.login(data);
        toast.promise(fetch, {
          loading: "Acessando...",
          success: "Logado com sucesso!",
          error: "Erro ao acessar",
        });

        const response = await fetch;
        console.log(response);

        if (response.status === `success`) {
          localStorage.setItem("jwt", response.jwt);
          localStorage.setItem("username", response.name);
          localStorage.setItem("user_id", response.user_id);
          localStorage.setItem("plate_id", response.plate_id);
          localStorage.setItem("user_role", response.role);
          localStorage.setItem("is_manager", response.manager);
          localStorage.setItem("efforts", JSON.stringify(response.efforts));
          setTimeout(() => {
            window.location.href = "/home";
          }, 1000);
        }
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      console.log(`Error: ${err.message}`);

      setLoginError(true);
      setIsMissingCpf(true);
      setIsMissingPsw(true);
      document.getElementById("outlined-basic").focus();

      submitBtn.disabled = false;
      submitBtn.innerHTML = "acessar";
    }
  };

  return (
    <div className={styles["container"]}>
      <Toaster />
      <img src={logo} alt="logo" />
      <p className="p-roboto-bold-16 lightBlue">
        Digite seus dados para acessar
      </p>
      <form onSubmit={handleSubmit}>
        <StyledCPF
          cpf={cpf}
          isMissingCpf={isMissingCpf}
          handleCpfChange={handleCpfChange}
          cpfClickHandler={cpfClickHandler}
        />
        <StyledPassword
          psw={psw}
          isMissingPsw={isMissingPsw}
          handleChangePsw={handleChangePsw}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />
        {loginError && (
          <p className="p-roboto-regular-13 red alert">
            Verifique seus dados e tente novamente.
          </p>
        )}

        <LoginButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

export default Login;
