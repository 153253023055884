import { useState } from "react";
import { cpfMask } from "../helpers/mask";

export default function useCpf() {
  const [isMissingCpf, setIsMissingCpf] = useState(false);
  const [cpf, setCpf] = useState("");

  const handleCpfChange = (event) => {
    setCpf(cpfMask(event.target.value));
  };

  const cpfClickHandler = (event) => {
    setCpf("");
  };

  return {
    isMissingCpf,
    setIsMissingCpf,
    cpf,
    handleCpfChange,
    cpfClickHandler,
  };
}
