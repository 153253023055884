import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

//components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

//Styles
import styles from "../styles/pages/afterWorkJustification.module.scss";

//Helpers
import api from "../services/api";

const StyledTextField = styled(TextField)({
  border: "1px solid #ffff",
  marginTop: "35px",
  height: "200px",
  color: "white",
  "& label": {
    color: "white",
    backgroundColor: "#0a3051",
  },
  "& label.Mui-focused": {
    color: "white",
    backgroundColor: "#0a3051",
  },
  textArea: {
    color: "white",
  },
});

const StyledBtn = styled(Button)({
  width: "100%",
  height: "67px",
  marginTop: "48px",
  padding: "10px",
  borderRadius: "100px",
  background: "#F8B32D",
  fontSize: "14px",
  letterSpacing: "0.1px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#AA6C00",
  "&:hover": {
    backgroundColor: "#F8B32D",
  },
  "&:disabled": {
    background: "none",
    border: "1px solid #F8B32D",
    color: "#F8B32D",
  },
});

export default function AfterWorkJustification() {
  const [justification, setJustification] = useState(
    localStorage.getItem("justification") || ""
  );
  const [isJustificationSended, setIsJustificationSended] = useState(
    localStorage.getItem("justification") ? true : false
  );
  const [isDisabled, setIsDisabled] = useState(true);

  const handleJustification = (e) => {
    setJustification(e.target.value);
    if (e.target.value.length > 0) setIsDisabled(false);
    else setIsDisabled(true);
  };

  const handleJustificationSubmit = (e) => {
    //prevent the page reloads
    e.preventDefault();

    //build the data
    const userId = localStorage.getItem("user_id");
    const data = {
      //parse the userId string into number
      userId: parseInt(userId),
      justification: justification,
    };
    console.log(data);

    //send the data
    api
      .sendApproval(data)
      .then((response) => {
        console.log(response);
        localStorage.setItem("justification", justification);
        if (response.status === "success") {
          toast.success("Autorização enviada com sucesso!");
          localStorage.setItem("is_justification_sent", true);
          setIsJustificationSended(true);
          setIsDisabled(true);
        }
      })
      .catch((err) => {
        toast.error("Erro ao enviar a autorização.");
        toast.error(err);
      });
  };

  return (
    <div className="container">
      <Toaster />
      <h1>Você precisa de autorização para lançar um RDO</h1>
      <p className="p-roboto-regular-13 red">
        Para acessar o aplicativo fora do horário de trabalho (das 7:00 às
        18:15), por favor, preencha o campo abaixo com uma justificativa válida
        para obter autorização de acesso.
      </p>
      <div className={styles.textField}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: "311px",
              height: "200",
              borderRadius: "4px",
              margin: "none",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <StyledTextField
              id="outlined-multiline-flexible"
              label="Digite sua Justificativa"
              multiline
              rows={7}
              value={justification}
              onChange={handleJustification}
              disabled={isJustificationSended}
            />
          </div>
        </Box>
      </div>
      <StyledBtn
        onClick={handleJustificationSubmit}
        {...(isDisabled || isJustificationSended
          ? { disabled: true }
          : { disabled: false })}
      >
        {isJustificationSended
          ? "JUSTIFICATIVA ENVIADA"
          : "ENVIAR JUSTIFICATIVA"}
      </StyledBtn>
    </div>
  );
}
