import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { MenuContext } from "../contexts/MenuContext";
import { Stack } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

export default function MenuGlobal({
  hasReturnButton = { show: false, toPathname: "" },
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { clearStorage, handleLogoutRequest, handleReturn } =
    React.useContext(MenuContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" justifyContent="flex-start">
        {hasReturnButton.show ? (
          <ArrowCircleLeftOutlinedIcon
            sx={{
              color: "white",
              fontSize: "50px",
              marginBottom: "15px",
            }}
            onClick={() => handleReturn(hasReturnButton.toPathname)}
          />
        ) : (
          <CloseIcon
            sx={{
              color: "#EF1B4E",
              fontSize: "50px",
              marginBottom: "15px",
            }}
            onClick={handleLogoutRequest}
          />
        )}
      </Stack>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: "#EF1B4E" }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem key={1} onClick={clearStorage}>
          Limpar Cache
        </MenuItem>
        {hasReturnButton.show && (
          <MenuItem key={2} onClick={handleLogoutRequest}>
            Logout
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
}
